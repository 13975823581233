import React, { useState, useEffect } from "react";
import "./FAQ.scss";
import { FAQNavConfig } from "../../data/NavBarConfig";
import { FAQData } from "../../data/FAQData";
import InnerNavbar from "../InnerNavbar/InnerNavbar";
import FAQList from "./FAQList/FAQList";
import Footer from "./../Footer/Footer";

const FAQ = ({ section, analytics }) => {

  const [currentSection, setCurrentSection] = useState(section || FAQNavConfig[0].value);

  useEffect(() => {
    analytics.pageview(window.location.pathname + '/' + currentSection);

    const pageContainerDiv = document.getElementById('dashboard-page-container');
    pageContainerDiv.scrollTo({top: 0, behavior: 'smooth'});
  }, [currentSection, analytics])

  return (
    <div>
      <InnerNavbar activeSection={currentSection} changeSection={setCurrentSection} sections={FAQNavConfig} ></InnerNavbar>
      <div className="page-container faq" id="dashboard-page-container">
        <div className="faq-list-container">
          <FAQList data={FAQData[currentSection]} faqListStyle={currentSection}/>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default (FAQ);
