import React from "react";
import { Row, Col } from "react-bootstrap";
import "./CitiesDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import InnerFooter from "../../InnerFooter/InnerFooter";
import blocks from "../../../assets/img/home/benefits/blocks.svg";
import hexagons from "../../../assets/img/home/benefits/hexagons.svg";

const CitiesDashboard = () => {
  return (
    <div>
      <div id="cities-header-element-div">
        <DashboardsHeader dashboardStyle="cities" />
      </div>

      <Row className="cities-section text-section" id="cities-text-element-div">
        <Col xs={12} sm={6} className="cities-text-section first">
          <div className="cities-text-wrapper">
            <div className="cities-text-header">Georreferenciamento</div>
            Ferramenta de visualização de consumo georreferenciada, ideal para
            clientes que precisam monitorar vários prédios ou unidades em uma
            única tela.
          </div>
        </Col>
        <Col xs={12} sm={6} className="cities-text-section second">
          <div className="cities-text-wrapper">
            <div className="cities-text-header">Dashboard</div>
            Dashboards utilizado para monitorar o consumo de energia de grandes
            áreas. Identificando onde está o maior consumo e visualizar o
            histórico detalhado de consumo.
          </div>
        </Col>
        <Col xs={12} sm={6} className="cities-text-section third">
          <div className="cities-text-wrapper">
            <div className="cities-text-header">Alertas</div>
            Através do dashboard, alertas indicam potenciais consumos atípicos.
            Esses alertas podem ser customizados de acordo com necessidade do
            cliente.
          </div>
        </Col>
        <Col xs={12} sm={6} className="cities-text-section fourth">
          <div className="cities-text-wrapper">
            <div className="cities-text-header">
              Controle de acesso hierárquico
            </div>
            Defina quem pode controlar atuadores e criar alarmes, além de
            visualizar informações detalhadas por bloco.
          </div>
        </Col>
      </Row>

      <div className="cities-section videos-section">
        <Row
          className="cities-section figure-section-wrapper"
          id="cities-figure-element-div"
        >
          <Col
            xs={12}
            className="cities-two-rows-section-img logo-section"
          ></Col>
          <img alt="hexagons" className="hexagons" src={hexagons} />
          <Col
            xs={12}
            className="cities-two-rows-section-img figure-section"
          ></Col>
        </Row>

        <img alt="blocks" className="blocks" src={blocks} />

        <Row className="cities-section visit-platform-button-section remove-left-right-margin justify-content-center">
          <a
            href="https://pmcg.liteme.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            className="plain-link"
          >
            <div className="visit-platform-button"> VISITAR PLATAFORMA </div>
          </a>
        </Row>

        <Row className="cities-section video-title">FUNCIONALIDADES</Row>

        <Row className="cities-section video">
          <Col xs={12}>
            <iframe
              title="LiteMe for Industries Video"
              src="https://www.youtube.com/embed/pHqLtHozw_Q"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-iframe"
            ></iframe>
          </Col>
        </Row>

        <Row className="cities-section video-title">GERANDO RELATÓRIO</Row>

        <Row className="cities-section video">
          <Col xs={12}>
            <iframe
              title="LiteMe for Industries Video"
              src="https://www.youtube.com/embed/ZpDmDZlO7u0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-iframe"
            ></iframe>
          </Col>
        </Row>
      </div>

      <InnerFooter secondLine={"JUNTE-SE A NÓS"} innerFooterStyle="cities" />
    </div>
  );
};

export default CitiesDashboard;
