import React from "react";
import "./DevicesIntegrationSection.scss";
import { Row, Col } from "react-bootstrap";

const DevicesIntegrationSection = () => {
  return (
    <Row
      className="home-devices-integration-section"
      id="home-devices-integration-element-div"
    >
      <Col xs={12} md={6} className="home-devices-integration-text-wrapper">
        <div className="home-devices-integration-text">
          <div className="home-devices-integration-header">
            NOSSOS <br /> DISPOSITIVOS
          </div>
          O LiteMe foi criado para ser{" "}
          <b>integrado a diversos tipos de medidores</b>; podemos destacar
          medidores de energia, água, temperatura, umidade, sensores de presença
          e até contadores de pessoas.
          <a href="/devices">
            <div className="home-devices-integration-btn">
              CONHEÇA OS DISPOSITIVOS
              <div className="right-arrow-dashboards"></div>
            </div>
          </a>
        </div>
      </Col>

      <Col xs={12} md={6} className="home-devices-integration-artwork"></Col>
    </Row>
  );
};

export default DevicesIntegrationSection;
