import React from "react";
import "./LiteCampusSection.scss";
import { Row, Col } from "react-bootstrap";

const LiteCampusSection = () => {
  return (
    <Row className="lite-campus-section" id="lite-campus-element-div">
      <Col xs={12} md={6} className="lite-campus-sub-section">
        <div className="lite-campus-artwork">
          <div className="lite-campus-img"></div>
        </div>
      </Col>
      <Col xs={12} md={6} className="lite-campus-text-wrapper">
        <div className="lite-campus-text">
          Ferramenta de visualização de consumo <b>georreferenciada</b>, ideal
          para clientes que precisam <b>monitorar vários prédios ou unidades</b>{" "}
          em uma única tela.
          <br />
          <br />
          <b>Medidores</b> instalados em unidades ou prédios coletam dados de
          consumo, de <b>energia</b> e <b>água</b>, que são enviados para a
          nuvem e processados.
          <br />
          <br />O <b>processamento na nuvem</b> simplifica a implantação,{" "}
          <b>reduz custos de operação</b>e provê mecanismos inovadores de
          segurança e <b>proteção</b> de privacidade dos usuários.
        </div>
      </Col>
    </Row>
  );
};

export default LiteCampusSection;
