import React from "react";
import { Row } from "react-bootstrap";
import "./InnerFooter.scss";

const InnerFooter = ({ firstLine, secondLine, innerFooterStyle }) => {
  return (
    <div
      className={"inner-footer-wrapper " + innerFooterStyle}
      id={innerFooterStyle + "-footer-element-div"}
    >
      {firstLine && (
        <Row className="justify-content-center inner-footer-label">
          {firstLine}
        </Row>
      )}
      <Row
        className={
          "justify-content-center inner-footer-label name " + innerFooterStyle
        }
      >
        {secondLine}
      </Row>
      <Row className="remove-left-right-margin justify-content-center">
        <a href="/join">
          <div className="inner-footer-button"> SEJA LiteMe </div>
        </a>
      </Row>
    </div>
  );
};

export default InnerFooter;
