import React from "react";
import "./InnovationSection.scss";
import { Row, Col } from "react-bootstrap";

const InnovationSection = () => {

  return (
    <Row className="home-innovation-section" id="home-innovation-element-div">
      <Col xs={12} md={6} className="home-innovation-text-wrapper">
        <div className="home-innovation-text">
          <b>Algoritmos sofisticados baseados em Inteligência Artificial</b> permitem estimar o consumo individual dos principais 
          equipamentos sem que seja necessário instalar um medidor em cada um deles.
          <br/><br/>
          O sistema torna-se cada vez <b>mais inteligente</b> à medida que a base de usuários vai aumentando. 
          A <b>arquitetura escalável</b> e extensível do LiteMe foi criada para permitir suportar grande quantidade de dados.
          <br/><br/>
          <b>Integre o consumo com outras plataformas</b>. O LiteMe pode ser integrado a diversas outras aplicações, os dados 
          coletados podem ser enviados para outras plataformas, bem como <b>outras aplicações podem acessar</b> e processar os dados coletados.
        </div>
      </Col>

      <Col xs={12} md={6} className="home-innovation-sub-section">
        <div className="home-innovation-artwork">
          <div className="home-innovation-img"></div>
          <div> INOVAÇÃO </div>
        </div>
      </Col>
    </Row>
  );
};

export default (InnovationSection);
