import React from "react";
import "./Home.scss";
import HomeHeader from "./HomeHeader/HomeHeader";
import HowItWorksSection from "./HowItWorksSection/HowItWorksSection";
import DevicesIntegrationSection from "./DevicesIntegrationSection/DevicesIntegrationSection";
import InnovationSection from "./InnovationSection/InnovationSection";
import AreasDashboardSection from "./AreasDashboardSection/AreasDashboardSection";
import LiteCampusSection from "./LiteCampusSection/LiteCampusSection";
import MapSection from "./MapSection/MapSection";
import BenefitsSection from "./BenefitsSection/BenefitsSection";
import JoinLitemeSection from "./JoinLitemeSection/JoinLitemeSection";
import Footer from "./../Footer/Footer";

const Home = () => {
  return (
    <div className="page-container">
      <HomeHeader />
      <HowItWorksSection />
      <div id="home-innovation-integration-element-div">
        <DevicesIntegrationSection />
        <InnovationSection />
      </div>
      <AreasDashboardSection />
      <LiteCampusSection />
      <MapSection />
      <BenefitsSection />
      <JoinLitemeSection />
      <Footer />
    </div>
  );
};

export default Home;
