import React from "react";
import { Row, Col } from "react-bootstrap";
import "./IndustryDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import GenerationSection from "../ResidenceDashboard/GenerationSection/GenerationSection";
import InnerFooter from "../../InnerFooter/InnerFooter";

const IndustryDashboard = () => {
  return (
    <div>
      <div id="industry-header-element-div">
        <DashboardsHeader dashboardStyle="industry" />

        <Row className="industry-initial-text">
          <Col xs={10} sm={7} className="industry-initial-text-col">
            O LiteMe tem sido aplicado na indústria de diversas formas. Podemos
            destacar: identificação de consumos atípicos em equipamentos
            específicos, detalhamento do consumo por área ou máquina, cálculo de
            custos com energia dentro do processo fabril ou até integração de
            dados de energia com BI. Devido às características multifacetadas do
            LiteMe, diversos outros tipos de aplicações podem ser destacadas.
          </Col>
        </Row>

        <Row className="industry-section video">
          <Col xs={12}>
            <iframe
              title="SmartCampus Video"
              src="https://www.youtube.com/embed/opPBn6OgLgo"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-iframe"
            ></iframe>
          </Col>
        </Row>

        <Row className="industry-section boxes-section">
          <Col
            xs={12}
            md={6}
            className="industry-two-columns-section-text boxes-section"
          >
            <div className="industry-two-columns-section-text-wrapper">
              <div className="industry-two-columns-section-header">
                Custo e consumo em tempo real, além da previsão de conta mensal
              </div>
              Acompanhamento em tempo real de informações sobre consumo e custo.
              Com base no seu padrão de consumo, o LiteMe estima o valor da sua
              conta de energia mensal.
            </div>
          </Col>

          <Col
            xs={12}
            md={6}
            className="industry-two-columns-section-img boxes-section"
          ></Col>
        </Row>
      </div>

      <Row
        className="industry-section peak-hour-section"
        id="industry-peak-hour-element-div"
      >
        <Col xs={12} className="industry-two-rows-section-text">
          <div className="industry-two-rows-section-text-wrapper">
            <div className="industry-two-rows-section-header">
              Horário de Ponta/Fora de Ponta
            </div>
            Detalhamento de custo e consumo em horários de ponta e fora de
            ponta. Gráficos permite claramente verificar se a redução do consumo
            no horário de ponta está de acordo com o planejado.
          </div>
        </Col>

        <Col
          xs={12}
          className="industry-two-rows-section-img peak-hour-section"
        ></Col>
      </Row>

      <Row
        className="industry-section industry-report-section"
        id="industry-report-element-div"
      >
        <Col xs={12} className="industry-two-rows-section-text">
          <div className="industry-two-rows-section-text-wrapper">
            <div className="industry-two-rows-section-header">
              Demanda contratada
            </div>
            Através de relatórios detalhados apresentando quando a demanda foi
            ultrapassada e por quanto tempo, veriﬁque se é necessário renegociar
            com a concessionária a demanda contratada.
          </div>
        </Col>

        <Col
          xs={12}
          className="industry-two-rows-section-img industry-report-section"
        ></Col>
      </Row>

      <Row
        className="industry-section factor-section"
        id="industry-factor-element-div"
      >
        <Col xs={12} className="industry-two-rows-section-text">
          <div className="industry-two-rows-section-text-wrapper">
            <div className="industry-two-rows-section-header">
              Fator de potência
            </div>
            Tenha mais controle sobre o fator de potência de sua indústria.{" "}
            <br />
            Saiba quando está indutivo ou capacitivo e evite multas.
          </div>
        </Col>

        <Col
          xs={12}
          className="industry-two-rows-section-img factor-section"
        ></Col>
      </Row>

      <Row
        className="industry-section table-section"
        id="industry-table-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="industry-two-columns-section-text table-section"
        >
          <div className="industry-two-columns-section-text-wrapper">
            <div className="industry-two-columns-section-header">
              Navegação por várias granularidades de tempo
            </div>
            Tabelas dinâmicas podem ser utilizadas para navegar em diversos
            meses, dias, horários e observar o consumo e a demanda. Através
            destas informações, descubra rapidamente qual área houve maior
            consumo ou demanda, bem como quando e quanto.
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          className="industry-two-columns-section-img table-section"
        ></Col>
      </Row>

      <Row
        className="industry-section bill-section"
        id="industry-bill-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="industry-two-columns-section-text bill-section"
        >
          <div className="industry-two-columns-section-text-wrapper">
            <div className="industry-two-columns-section-header">
              Detalhamento de contas industriais
            </div>
            Entenda em detalhes a conta de energia. Saiba a composição de cada
            termo da sua conta. Através do detalhamento da conta, é possível
            discriminar por dia e horário o custo e o consumo relacionado com
            consumo em horários fora de ponta, consumo em horários de ponta,
            demanda contratada, energia reativa e multas por ultrapassar demanda
            contratada ou violar limites do fator de potência.
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          className="industry-two-columns-section-img bill-section"
        ></Col>
      </Row>

      <GenerationSection forIndustry />

      <Row
        className="commerce-section sensors-section"
        id="commerce-sensors-element-div"
      >
        <Col xs={12} className="commerce-two-rows-section-text">
          <div className="commerce-two-rows-section-text-wrapper">
            <div className="commerce-two-rows-section-header">
              Vários medidores
            </div>
            Gerencie vários estabelecimentos comerciais em uma única conta.
            Compare o consumo entre eles.
          </div>
        </Col>

        <Col
          xs={12}
          className="commerce-two-rows-section-img sensors-section"
        ></Col>
      </Row>

      <Row
        className="commerce-section report-section"
        id="commerce-report-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-img report-section"
        ></Col>

        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-text report-section"
        >
          <div className="commerce-two-columns-section-text-wrapper">
            <div className="commerce-two-columns-section-header">
              Relatórios automáticos
            </div>
            <br />
            Receba relatórios semanais e mensais detalhando seu consumo.
            Monitore seu consumo com mais intimidade. Entenda como o conta de
            energia é calculada e descubra quais dias e horários ocorreram os
            maiores consumos.
          </div>
        </Col>
      </Row>

      <Row
        className="commerce-section history-section"
        id="commerce-history-element-div"
      >
        <Col xs={12} className="commerce-two-rows-section-text">
          <div className="commerce-two-rows-section-text-wrapper">
            <div className="commerce-two-rows-section-header">Histórico</div>
            Através das informações apresentadas no histórico detalhado, seu
            eletricista pode descobrir desbalanceamento de fases, tensões fora
            dos limites deﬁnidos pela concessionária, consumos anormais em
            equipamentos instalados, bem como diversas outros tipos de
            problemas.
          </div>
        </Col>

        <Col
          xs={12}
          className="commerce-two-rows-section-img history-section"
        ></Col>
      </Row>

      <InnerFooter
        firstLine={"Transforme sua"}
        secondLine={"INDÚSTRIA"}
        innerFooterStyle="industry"
      />
    </div>
  );
};

export default IndustryDashboard;
