import * as FAQ from "./FAQTopics";

const FAQData = {
  general: {
    title: "Geral",
    faqs: FAQ.GeneralData
  },
  measurements: {
    title: "Medição",
    faqs: FAQ.MeasurementsData
  },
  fundamentals: {
    title: "Fundamentos",
    faqs: FAQ.FundamentalsData
  },
  fares: {
    title: "Tarifas",
    faqs: FAQ.FaresData
  },
  actuators: {
    title: "Atuação",
    faqs: FAQ.ActuatorsData
  },
  tips: {
    title: "Dicas",
    faqs: FAQ.TipsData
  },
  partners: {
    title: "Parcerias",
    faqs: FAQ.PartnersData
  },
  termsAndConditions: {
    title: "Termos e Políticas",
    faqs: FAQ.TermsAndConditionsData
  }
};

const FAQNavConfig = [
  {
    value: "general",
    label: "Geral"
  },
  {
    value: "measurements",
    label: "Medição"
  },
  {
    value: "fundamentals",
    label: "Fundamentos"
  },
  {
    value: "fares",
    label: "Tarifas"
  },
  {
    value: "actuators",
    label: "Atuação"
  },
  {
    value: "tips",
    label: "Dicas"
  },
  {
    value: "partners",
    label: "Parcerias"
  },
  {
    value: "termsAndConditions",
    label: "Termos e Políticas"
  }
];

export { FAQData, FAQNavConfig };
