import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./ImageSlider.scss";
import HomeSlider1 from "../../../../../assets/img/image-slider/img-slider-1.png";
import HomeSlider2 from "../../../../../assets/img/image-slider/img-slider-2.png";
import HomeSlider3 from "../../../../../assets/img/image-slider/img-slider-3.png";
const ImageSlider = ({ forCommerce, forIndustry }) => {
  return (
    <div className="ImageSliderWrapper">
      <Carousel controls={true} indicators={false} interval={5000}>
        <Carousel.Item>
          <img className="slider-img" src={HomeSlider1} alt="Slide 1" />
          <Carousel.Caption>
            <div className="img-slide-caption">
              Compare quanto foi gerado e a diferença entre energia importada e
              exportada para a concessionária (balanço).
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="slider-img" src={HomeSlider2} alt="Slide 2" />
          <Carousel.Caption>
            <div className="img-slide-caption">
              Acompanhe o consumo e geração nos últimos 5 minutos, hoje e na
              conta que ainda está sendo calculada.
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="slider-img" src={HomeSlider3} alt="Slide 3" />
          <Carousel.Caption>
            <div className="img-slide-caption">
              Visualize em uma única tela o consumo e a geração.
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default ImageSlider;
