const generalData = [
  {
    question: `O que é o LiteMe?`,
    answer: `LiteMe é uma tecnologia de ponta que ajuda indústrias, comércios e residências a monitorar e identificar os potenciais de economia do seu consumo. Isso significa que você terá acesso ao quanto pode ser economizado, poderá monitorar a qualidade da sua energia, evitar multas e ainda possuir dados em tempo real do seu empreendimento. 
      O software é solução na nuvem que monitora diversos pontos de consumo e apresenta tudo em um único dashboard sofisticado. Através dessa tela, é possível customizar que informações irão aparecer e com isso criar um ambiente de monitoramento especial.`
  },
  {
    question: `O que é o LiteCampus?`,
    answer: `LiteCampus é um sistema de gerenciamento energético que entrega o histórico e dados em tempo real sobre o consumo energético em ambientes onde os pontos de consumo (ex., blocos, galpões) estão espalhados por uma grande área, como em cidades universitárias. O diferencial do software está na oferta de dashboards que permitem uma visão geral destes grandes ambientes, conectando com o LiteMe quando para uma análise mais detalhada de um ponto de consumo específicio. Para a gerência de grandes ambientes o LiteCampus oferece funcionalidades como georreferenciamento dos pontos de consumo (ex., blocos), criação de alertas para atuação em larga escala, controle de acesso às funcionalidade de monitoramento e atuação por ponto de consumo, entre outros. 

      Com a utilização de atuadores locais, a plataforma automatiza o ligar e desligar equipamentos energéticos de ambientes como salas de aulas. Isso garante a liberdade para o gestor programar os períodos em que os equipamentos, como ar condicionado, serão habilitados. `
  },
  {
    question: `Como faço para instalar um LiteMe?`,
    answer: `Preencha o formulário que um dos nosso representantes comerciais entrará em contato.`
  },
  {
    question: `Quanto custa?`,
    answer: `O preço irá variar dependendo do tipo de medidor, da quantidade de medidores, das customizações sugeridas e dos módulos que serão acessados. Preencha o formulário que entraremos em contato.`
  },
  {
    question: `Pode ser customizado?`,
    answer: `Sim, vários tipos de customizações podem ser realizadas.`
  },
  {
    question: `Que tipo de customização?`,
    answer: `Criação de novos componentes, integração com outras fontes de dados, criação de novos tipos de indicadores, dashboards para gestão de vários pontos de medição.`
  },
  {
    question: `Como devo proceder para customizar?`,
    answer: `Preencha o formulário que entraremos em contato para entender as necessidades.`
  }
];

export default generalData;
