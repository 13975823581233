import React from "react";
import { Row, Col } from "react-bootstrap";
import "./CommerceDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import GenerationSection from "../ResidenceDashboard/GenerationSection/GenerationSection";
import InnerFooter from "../../InnerFooter/InnerFooter";

const CommerceDashboard = () => {
  return (
    <div>
      <div id="commerce-header-element-div">
        <DashboardsHeader dashboardStyle="commerce" />

        <Row className="commerce-initial-text">
          <Col xs={10} sm={7} className="commerce-initial-text-col">
            Gerencie melhor a energia de estabelecimentos comerciais, compare
            unidades consumidoras e identifique consumos não esperados.
          </Col>
        </Row>
      </div>

      <Row className="residence-section residence-boxes-section">
        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-img residence-boxes-section for-commerce"
        ></Col>

        <Col xs={12} md={6} className="residence-two-columns-section-text">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header">
              Custo e consumo em tempo real, além da previsão de conta mensal
            </div>
            <br />
            Acompanhamento em tempo real de informações sobre consumo e custo.
            Com base no seu padrão de consumo, o LiteMe estima o valor da sua
            conta de energia mensal.
          </div>
        </Col>
      </Row>

      <Row
        className="residence-section devices-section"
        id="residence-devices-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-text devices-section"
        >
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header">
              Consumo dos principais aparelhos
            </div>
            <br />
            Algoritmos soﬁsticados baseados em técnicas avançadas de
            Inteligência Artiﬁcial permitem estimar o consumo dos principais
            equipamentos sem que seja necessário colocar um medidor em cada
            equipamento.
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-img devices-section for-commerce"
        ></Col>
      </Row>

      <Row
        className="residence-section always-on-section"
        id="residence-always-on-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-img always-on-section for-commerce"
        ></Col>

        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-text always-on-section"
        >
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header">
              Consumo sempre ligado
            </div>
            <br />
            Apresenta o consumo que nunca desliga. Em geral, este consumo é
            representado por refrigeradores e aparelhos ﬁcam em 'stand-by'.
            <br />
            <br />
            Estima-se que, em média, este consumo seja aproximadamente 10% do
            consumo total de uma residência.
          </div>
        </Col>
      </Row>

      <Row
        className="residence-section efficiency-section"
        id="residence-efficiency-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-text efficiency-section"
        >
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header">
              Eficientímetro
            </div>
            <br />
            Informa se o consumo agora está maior ou menor em relação ao
            histórico de consumo. O gráﬁco no formato de um 'velocímetro' indica
            o quanto o consumo está maior ou menor.
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-img efficiency-section for-commerce"
        ></Col>
      </Row>

      <Row
        className="residence-section period-consumption-section"
        id="residence-period-consumption-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-img period-consumption-section for-commerce"
        ></Col>

        <Col
          xs={12}
          md={6}
          className="residence-two-columns-section-text period-consumption-section"
        >
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header">
              Consumo por período
            </div>
            <br />
            Permite entender em que turno ocorre o maior consumo e quanto ele
            representa.
          </div>
        </Col>
      </Row>

      <GenerationSection forCommerce />

      <Row className="commerce-section consumption-section">
        <Col xs={12} className="commerce-two-rows-section-text">
          <div className="commerce-two-rows-section-text-wrapper">
            <div className="commerce-two-rows-section-header">
              Compare seu consumo
            </div>
            Compare por medidor, por dia, semana, período.
          </div>
        </Col>

        <Col
          xs={12}
          className="commerce-two-rows-section-img consumption-section"
        ></Col>
      </Row>

      <Row
        className="commerce-section alerts-section"
        id="commerce-alerts-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-text alerts-section"
        >
          <div className="commerce-two-columns-section-text-wrapper">
            <div className="commerce-two-columns-section-header">
              Recomendações e Alertas
            </div>
            <br />
            Vários tipos de Recomendações e Alertas pré-cadastrados podem ser
            customizados para emissão de notiﬁcações sobre consumos não
            esperados.
            <br />
            <br />
            Seja notificado sobre consumos atípicos antes de chegar a conta de
            energia e tome ações necessárias para reduzir o consumo.
          </div>
        </Col>

        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-img alerts-section"
        ></Col>
      </Row>

      <Row
        className="commerce-section sensors-section"
        id="commerce-sensors-element-div"
      >
        <Col xs={12} className="commerce-two-rows-section-text">
          <div className="commerce-two-rows-section-text-wrapper">
            <div className="commerce-two-rows-section-header">
              Vários medidores
            </div>
            Gerencie vários estabelecimentos comerciais em uma única conta.
            Compare o consumo entre eles.
          </div>
        </Col>

        <Col
          xs={12}
          className="commerce-two-rows-section-img sensors-section"
        ></Col>
      </Row>

      <Row
        className="commerce-section report-section"
        id="commerce-report-element-div"
      >
        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-img report-section"
        ></Col>

        <Col
          xs={12}
          md={6}
          className="commerce-two-columns-section-text report-section"
        >
          <div className="commerce-two-columns-section-text-wrapper">
            <div className="commerce-two-columns-section-header">
              Relatórios automáticos
            </div>
            <br />
            Receba relatórios semanais e mensais detalhando seu consumo.
            Monitore seu consumo com mais intimidade. Entenda como o conta de
            energia é calculada e descubra quais dias e horários ocorreram os
            maiores consumos.
          </div>
        </Col>
      </Row>

      <Row
        className="commerce-section history-section"
        id="commerce-history-element-div"
      >
        <Col xs={12} className="commerce-two-rows-section-text">
          <div className="commerce-two-rows-section-text-wrapper">
            <div className="commerce-two-rows-section-header">Histórico</div>
            Através das informações apresentadas no histórico detalhado, seu
            eletricista pode descobrir desbalanceamento de fases, tensões fora
            dos limites deﬁnidos pela concessionária, consumos anormais em
            equipamentos instalados, bem como diversas outros tipos de
            problemas.
          </div>
        </Col>

        <Col
          xs={12}
          className="commerce-two-rows-section-img history-section"
        ></Col>
      </Row>

      <InnerFooter
        firstLine={"Transforme sua"}
        secondLine={"EMPRESA"}
        innerFooterStyle="commerce"
      />
    </div>
  );
};

export default CommerceDashboard;
