import React from "react";
import { Row } from "react-bootstrap";
import "./AboutUs.scss";
import Partners from "./Partners/Partners";
import Footer from "./../Footer/Footer";

const AboutUs = () => {
  return (
    <div className="page-container">
      <div className="about-us-container">
        <Row className="about-us-row">
          <div className="about-us-header-artwork-wrapper">
            <img
              className="about-us-header-artwork"
              src={require("../../assets/img/partners/about-us-header-artwork.svg")}
              alt="about-us-header-artwork"
            />
          </div>
        </Row>
        <Row className="about-us-row">
          <div className="about-us-list-header">QUEM SOMOS</div>
        </Row>
        <Row className="about-us-row">
          <div className="about-us-about-text">
            Desenvolvemos soluções inteligentes de Eficiência Energética desde
            2012 e fomos pioneiros em Inteligência Energética. Essa habilidade
            foi desenvolvida com nossos clientes e parceiros a partir da
            combinação da Inteligência Artificial e Eficiência Energética. Essa
            combinação permite o entendimento de onde e quando gastam mais, além
            da identificação de oportunidades de economia de energia. O LiteMe
            pode ser utilizado por usuários residenciais, comerciais,
            industriais, concessionárias de Energia Elétrica e clientes de
            grande porte. Destaca-se também soluções baseadas em Redes Neurais
            para identificação de cargas (NILM), o que permite separar as cargas
            sem que seja necessário ter um medidor em cada equipamento.
            <br />
            <br />
            Nossa equipe é formada por Engenheiros de Software, Engenheiros
            Eletricistas, Analistas de Sistemas e Designers, incluindo mestres e
            doutores, e o que nós fazemos de melhor é descobrir onde você está
            gastando mais, porque, para nós, a informação é a chave para o uso
            consciente dos recursos naturais.
          </div>
        </Row>
      </div>
      <Partners />
      <Footer />
    </div>
  );
};

export default AboutUs;
