const fundamentalsData = [
  {
    question: `O que é energia?`,
    answer: `É a capacidade de realizar algum trabalho, é medida em joules (J)`
  },
  {
    question: `Quais as formas de energia?`,
    answer: `- Mecânica (cinética, potencial, elástica) 
        - Elétrica (cargas elétricas em um campo)
        - Química (alterações químicas - oxidação)
        - Térmica (variação de temperatura, radiação)
        - Nuclear`
  },
  {
    question: `O que é potência?`,
    answer: `A potência ativa (P) é a velocidade com que um trabalho é realizado, isto é, a razão entre o valor (em Joules) para realização do trabalho dividido pelo tempo (em segundos) para realizá-lo. Nessa lógica, a unidade de medida da potência ativa é o J/s (Joule por segundo), que é numericamente equivalente a unidade de medida Watts (W).

        Além da potência ativa, temos a potência reativa (Q). Essa é a potênciaque fica armazenada no campo elétrico e que não é capaz de realizar trabalho. Assim como a potência ativa, ela é passada pelos condutores da rede elétrica, mas não é convertida em outras formas de energia para realização de trabalhos necessários.
        
        Podemos ainda combinar essas duas potências e obter a potência aparente (S). Essa é a potência total que percorre os cabos da rede, causando perdas pelo efeito Joule.`
  },
  {
    question: `Quais são os tipos de potência?`,
    answer: `Potência ativa(P): potência útil, gera trabalho
        Potência reativa(Q): armazenada no campo elétrico ou magnético (entra e sai do sistema)
        Potência aparente(S): percorre os cabos causando as perdas por efeito joule (por isso a reativa importa)`
  },
  {
    question: `O que é tensão?`,
    answer: `A Tensão Elétrica, ou DDP (Diferança de Potencial) é a grandeza elétrica que mede a diferença de potêncial elétrico entre dois pontos. Ela é responsável pela ordenação das cargas livres (o que gera a corrente elétrica), pois os elétrons tendem a sair do ponto de maior potencial elétrico para o de menor, gerando um movimento ordenado. A unidade de medida dessa grandeza é o Volt (V).`
  },
  {
    question: `O que é lúmen?`,
    answer: `Lúmen é a unidade de medida de fluxo luminoso no Sistema Internacional. De modo mais prático, podemos entender um lúmen como sendo a quantidade de luz que é emitida por uma lâmpada e nossos olhos conseguem perceber.`
  },
  {
    question: `Quais as leis naturais fundamentais?`,
    answer: `- Primeira lei da termodinamica: a energia não se cria nem se destrói
        - Segunda lei da termodinâmica: a energia gasta não pode ser completamente restaurada, por isso é falado em eficiência energética`
  },
  {
    question: `O que é eficiencia energética?`,
    answer: `Utilizar a energia da forma mais eficiente possivel, minimizando a perda natural da energia para o meio. Consiste na fórmula = energia útil / energia de fato usada`
  },
  {
    question: `O que é demanda e quais os tipos?`,
    answer: `Demanda está relacionada à disponibilidade de energia elétrica e possui os seguintes tipos:
        - Demanda contratada: se paga pela capacidade instalada, utilizada ou não
        - Demanda de ultrapassagem: tarifa pela ultrapassagem da capacidade contratada
        - Demanda medida: maior demanda no intervalo de 15 minutos`
  },
  {
    question: `O que é o Efeito Joule?`,
    answer: `Podemos definir o Efeito Joule como sendo um fenômeno físico decorrente da passagem de uma corrente elétrica por um condutor, gerando seu aquecimento e dissipando energia na forma de calor.
        Esse fenômeno pode apresentar uma perda energética, quando não há intensão de dissipar calor pelo sistema, porém, algumas vezes ele é utilizado com essa exata finalidade, como é o caso dos chuveiros elétricos.`
  },
  {
    question: `O que é o fator de potência?`,
    answer: `O fator de potência é o percentual de potência ativa (P) em relação a potência aparente (S). Essa variável é importante para sabermos se um equipamento é eficiente do ponto de vista do seu trabalho realizado, isto é, se a potência que realiza trabalho é a maior parte da potência total do sistema.

        A relação entre o fator de potência e a potência reativa (Q) é que, quanto menor a potência reativa de um sistema, se mantendo a quantidade de potência ativa, maior será o fator de potência, significando que maior será a quantidade de energia útil no sistema.
        
        Na nossa conta de energia é paga uma taxa de fator de potência. O fator de potência objetivo é de 0,92 ou superior. Para valores abaixo disso, o custo pelo preço da energia será injusto para o usuário, visto que ele terá que pagar por uma energia que não foi utilizada (já que a potência reativa não realiza trabalho). Além disso, também é paga uma taxa proporcional a quantidade de energia reativa no sistema.`
  },
  {
    question: `Quais são os tipos de consumidores de energia?`,
    answer: `Consumidor cativo: precisa comprar energia da distribuidora local
        Grande consumidor: pode comprar energia a um outro fornecedor, existem os seguintes tipos:
        - Livres: com demanda acima de 2MW
        - Especiais: com demanda acima de 500kW, que podem comprar somente com fontes especiais, como: eólica, solar, biomassa pequenas centrais hidrelétricas (PCHs) ou hidráulica de empreendimentos com potência inferior ou igual a 50.000kW`
  },
  {
    question: `O que é Mercado Livre?`,
    answer: `É um contrato onde apenas consumidores com demanda acima de 500kW podem ingressar no mercado livre e ter as seguintes vantagens nos contratos:

        - Previsibilidade: evitando reajustes e bandeiras
        - Flexibilidade: o consumidor escolhe a empresa fornecedora de energia, preço que quer pagar, período de contratação, eventuais flexibilidades de consumo…
        - Energia paga ao fornecedor, mas o uso do sistema é pago à distribuidora local (proporcional ao consumo e demanda, “serviço de fio”)
        - O consumidor pode operar no mercado diretamente (associar-se a Câmara de Comercialização de Energia Elétrica) ou usar um comercializador`
  },
  {
    question: `O que é a qualidade da energia?`,
    answer: `A qualidade da energia está vinculada a facilidade de extração e geração da mesma por meio de uma fonte. Quanto maior a capacidade de geração de energia em uma fonte, maior é a qualidade da energia gerada.`
  },
  {
    question: `Quais os principios gerais da gerência de energia?`,
    answer: `Podemos listar alguns princípios da gerência de energia como sendo:

        - Revisão de dados históricos
        - Operação e Manutenção
        - Substituição de equipamentos defeituosos
        - Avaliação econômica do consumo
        - Troca de fontes energéticas
        
        Dentre muitos outros.`
  },
  {
    question: `Qual a importância de revisar dados históricos?`,
    answer: `A importância da revisão e análise de dados históricos é entender o consumo de energia dentro do perfil de um usuário ou um setor. Com isso, é possível identificar padrões de consumo referentes a época do ano, períodos do dia, faixas do mês, dias da semana, o que implica diretamente na identificação de possíveis anomalias, que podem estar vinculadas a algum problema pontual ou a necessidade de manutenção ou troca de um aparelho ou da própria rede elétrica. 

        Essa prática pode auxiliar na detecção de possíveis combinações de operações que irão favorecer o bom uso da energia dentro do contexto, além de trazer ciência do consumo de energia para quem a utiliza, fazendo com que o usuário possa tomar medidas de eficiência energética.`
  },
  {
    question: `O que é uma microgrid?`,
    answer: `As microgrids são pequenas redes de geração e demanda de energia e que podem operar de forma independente ou conectadas ao sistema elétrico. 

        Podemos ter como exemplo de microgrid uma casa que gera a própria energia a partir de painéis solares,armazena o que é gerado em uma bateria e está conectada ao sistema elétrico por via de segurança, além de ter um sistema que funciona por meio de dispositivos de Internet das Coisas para auxiliar na gerência e diagnóstico da grid.
        
        Fonte: https://br.mouser.com/applications/new-technology-benefits-microgrids/`
  },
  {
    question: `O que é uma Virtual Power Plant?`,
    answer: `Podemos entender uma VPP (Virtual Power Plant) como sendo uma usina de energia virtual que integra um conjunto de microgrids. Uma VPP gerencia de forma hierárquica as microgrids a ela conectadas de modo a permitir um balanço global da geração de energia dessas células,tudo isso de maneira virtual partindo da integração de um sistema que utiliza dispositivos de Internet das Coisas para seu monitoramento.`
  },
  {
    question: `O que é Internet das Coisas?`,
    answer: `De modo geral, o conceito de Internet das Coisas está vinculado à integração de objetos de uso cotidiano a uma rede de computadores, por meio de sensores e tecnologias de conexão sem fio, com o objetivo de recolher e transmitir dados por meio dessa rede. 

        Essa tecnologia promete revolucionar os meios diversos setores e é a chave para a evolução dos ambientes inteligentes, como as smart homes, os smart campus e as smart cities. 
        
        Do ponto de vista da gerência de energia, a existência de dispositivos de Internet das Coisas é essêncial para que haja o monitoramento da demanda e do consumo de micro grids conectadas a um sistema maior, além de ser auxiliar enormemente na busca pelo uso mais eficiênte da energia.`
  },
  {
    question: `O que é um sistema ciber-físico?`,
    answer: `Podemos descrever um Sistema Ciber-físico como sendo o elo de comunicação dos sistemas físicos com os digitais, por meio de Internet das Coisas, unindo o mundo físico ao virtual. 

        Quando a inteligência computacional provinda do processamento dos dados recolhidos precisa ser retornada aos dispositivos, a fim de controlá-los segundo algumas métricas, há um ambiente ideal para o uso desses sistemas.`
  },
  {
    question: `O que é Indústria 4.0?`,
    answer: `Indústria 4.0, também denominada por Quarta Revolução Industrial, é um termo que diz respeito a nova fase industrial que iremos adentrar, a qual utiliza de tecnologias de automação para coleta, troca e processamento de dados por meio de dispositivos de Internet das Coisas, sistemas ciber-físicos, computação na nuvem e Big Data. 

        O maior foco dessa revolução é a busca por melhorar a eficiência energética e a produtividade dos processos com o uso dessas tecnologias da informação e comunicação disponíveis.`
  },
  {
    question: `O que são sensores e quais os seus tipos?`,
    answer: `São aparelhos que medem uma quantidade e provê um sinal como saída, normalmente um sinal elétrico.
        Os tipos de senores são: 
        - Digital: transforma uma grandeza física em um dado discreto
        - Analógico: transforma uma grandeza física em um sinal contínuo
        - Binários: só possuem duas situações (aberto e fechado/ligado e desligado...)`
  },
  {
    question: `Quais são as características de um sensor?`,
    answer: `- Velocidade
        - Características dinâmicas
        - Faixa de entrada
        - Faixa de saída
        - Sensibilidade (o quanto a variação da entrada influencia na variação da saída)
        - Resolução (quantas casas decimais o sensor mede)
        - Linearidade
        - Offset (enviezamento)
        - Acurácia (soma dos erros, quão perto da verdade o sensor está)
        - Precisão (variação entre medidas)
        - Histerese (perda nas instalações)
        - Drift
        - Efeito de carga (se o ato de medir atrapalha a grandeza)
        - Ruído (fatores que atrapalham a medição)`
  },
  {
    question: `O que é acurácia?`,
    answer: `A acurácia é uma métrica referente a porcentagem da proximidade entre um valor real e um valor obtido por meio de uma experimento. Quando maior a porcentagem da acurácia, mais próximo se dá o valor predito do valor real, ao mesmo passo que, quanto mais distantes os valores, menor será a porcentagem.`
  },
  {
    question: `O que é offset?`,
    answer: `O offset pode também ser descrito como o viés e é uma métrica referente ao enviesamento dos valores obtidos por experimentação em relação aos valores reais.`
  },
  {
    question: `O que é precisão?`,
    answer: `A precisão é uma métrica que se refere a proximidade entre os dados obtidos em uma experimentação. Diferentemente da acurácia, a precisão não se preocupa com o valor real, mas sim com a distância dos valores que foram preditos pelo experimento, quanto maior a distância, isto é, quanto mais espalhados forem os dados preditos, menor é a precisão, e quanto menor a distância, ou seja, quanto mais próximas de um ponto comum, maior é a precisão.`
  },
  {
    question: `O que é um transformador?`,
    answer: `É um equipamento que converte tensão de um nível para outro.`
  },
  {
    question: `Quais são os tipos de perda em transformadores?`,
    answer: `- Histerese: perda em componentes magnéticos, provocadas pela magnetização que pertence no material até o fim do estímulo. Essa magnetização precisa então de uma força contrária para ser desfeita e voltar ao seu estado inicial
        - Foucault: são correntes parasitas que causam perdas nos transformadores e outros componentes magnéticos.`
  },
  {
    question: `Como evitar perda nos transformadores?`,
    answer: `Procurar o balanceamento entre a carga do transformador e o seu dimensionamento, pois as perdas que acontecem pelos efeitos magnéticos são fixas. Portanto, transformadores subdimensionados vão ter uma perda fixa magnética desproporcionalmente alta. Assim, um carregamento na faixa de 30 a 80% é um bom compromisso entre rendimento e durabilidade.`
  },
  {
    question: `O que é motor?`,
    answer: `Motor é um dispositivo que transforma outras fontes de energia em energia mecânica e energia útil para movimentar máquinas, equipamentos e veículos. Precisma de um campo magnético para funcionar e não evoluíram muito com o tempo.`
  },
  {
    question: `Quais são os tipos de motores?`,
    answer: `- Motor de indução ou assíncrono (é o mais comum): quando o fluxo induzido no rotor precisa que a velocidade dele seja diferente da do campo de fora. 
        - Motor síncrono: quando o rotor é alimentado e então o rotor tenta sempre acompanhar o campo`
  },
  {
    question: `Como é o rendimento dos motores?`,
    answer: `São componentes magnéticos, então tem perdas semelhantes aos transformadores. No caso do de indução, mais comum, seu desempenho energético depende muito de seu nível de carga.`
  },
  {
    question: `Como funciona o fator de potencia dos motores?`,
    answer: `A carga afeta o fator de potência. Um motor subdimensionado terá um ator de potência muito baixo.`
  },
  {
    question: `Como funciona a eficiencia dos motores?`,
    answer: `- Motores de maiores potências tendem a ser mais eficientes
        - O rendimento varia com o número de pólos (velocidade): a velocidade poderia ser compensada por meios mecânicos
        - Comumente a eficiência é maior em torno de 75% da carga
        - Abaixo de 50% a eficiência cai rapidamente
        - o FP dos motores assíncronos é sempre baixo
        - A compensação (ex., instalação do capacitor) mitiga os efeitos negativos do excesso de potência aparente`
  },
  {
    question: `O que seria um bom sistema de iluminação?`,
    answer: `Um sistema que gerencia bem a iluminação e considera fatores como:
        - Aproveitamento da luz natural
        - Observância das normas de iluminação de interiores
        - Circuitos independentes para iluminação parcial
        - Seleção cuidados de lâmpadas, luminárias
        - Uso de mecanismos de automação para redução do consumo`
  },
  {
    question: `O que saber sobre iluminção?`,
    answer: `A iluminação representa um consumo menor (comparada a outros componentes como motores, ar condicionado...) e é mais fácil de ser gerenciada.`
  }
];

export default fundamentalsData;
