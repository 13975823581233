import React from "react";
import "./GenerationSection.scss";
import { Row, Col } from "react-bootstrap";
import ImageSlider from "./ImageSlider/ImageSlider";

const GenerationSection = ({ forCommerce, forIndustry }) => {
  return (
    <Row
      className="home-generation-section"
      id="generation-section-element-div"
    >
      <Col md={12} className="home-section-header">
        CONSUMO | GERAÇÃO
      </Col>

      <Col md={12}>
        <ImageSlider forCommerce={forCommerce} forIndustry={forIndustry} />
      </Col>
    </Row>
  );
};

export default GenerationSection;
