const partnersData = [
  {
    question: `Existe exclusividade?`,
    answer: `Não existe exclusividade com parceiros comerciais. A empresa LiteMe possui um CRM distribuído para acompanhamento de todas as ações dos parceiros comerciais.`
  },
  {
    question: `Qual o modelo de negócio?`,
    answer: `O modelo de negócio é baseado em uma taxa de instalação e uma mensalidade. Os integradores recebem uma parcela da mensalidade continuamente e são responsáveis pela instalação e pelo suporte local.`
  },
  {
    question: `Como faço para ser um parceiro?`,
    answer: `Preencha o formulário, que entraremos em contato. Iremos avaliar o tipo de cliente que deseja aplicar e a localização geográfica. Embora não exista exclusividade, evitamos dois parceiros comerciais atuando no mesmo tipo de cliente e na mesma região geográfica.`
  }
];

export default partnersData;
