import React from "react";
import "./JoinLitemeSection.scss";
import { Row, Col } from "react-bootstrap";

const JoinLitemeSection = () => {
  return (
    <Row className="join-liteme-section" id="join-liteme-element-div">
      <Col xs={12} md={6} className="join-liteme-sub-section">
        <div className="join-liteme-artwork">
          <img
            className="join-liteme-img"
            src={require("../../../assets/img/home/join-liteme/city.png")}
            alt="City"
          ></img>
        </div>
      </Col>
      <Col xs={12} md={6} className="join-liteme-btn-wrapper">
        <a href="/join">
          <div className="join-liteme-button"> SEJA LiteMe </div>
        </a>
      </Col>
    </Row>
  );
};

export default JoinLitemeSection;
