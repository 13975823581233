import React from "react";
import "../../TermsAndConditions.scss";
import Footer from "../../../Footer/Footer";

const GeneralPrivacy = () => {
  return (
    <div className="terms-and-conditions-wrapper">
      <div className="terms-and-conditions-header2">
        Versão 1.0 (04 de dezembro de 2020)
      </div>
      <div className="terms-and-conditions-header">
        LiteCampus - Política Privacidade
      </div>
      <div className="terms-and-conditions-header2">
        A privacidade dos seus dados é imporante para a RNP!
        <br />
        <br />
      </div>
      <div className="terms-and-conditions-text">
        Obrigado por usar o Serviço do LITECAMPUS! Nosso compromisso é proteger
        a privacidade e a segurança dos dados pessoais envolvidos na utilização
        do nosso serviço <b>LITECAMPUS</b>.
        <br />
        <br />
        Esta política de privacidade (<b>"Política"</b>) descreve como coletamos
        e utilizamos suas informações pessoais e abrange o seu uso e acesso à
        solução de Monitoramento e Eficientização do Consumo de Energia e Água
        assim denominados (<b>"Serviço do LITECAMPUS"</b>) disponibilizados a
        você.
        <br />
        <br />
        Esta política se aplica especificamente ao <b>"Serviço LITECAMPUS"</b>,
        a ser fornecida antes da coleta e baseia-se nas seguintes premissas
        gerais:
        <br />
        <br />
        <div className="terms-and-conditions-title3">Confidencialidade </div>
        Permitir o acesso aos dados e aos nossos sistemas somente a pessoas
        autorizadas, conforme o princípio do privilégio mínimo.
        <br />
        <br />
        <div className="terms-and-conditions-title3">Integridade </div>
        Preservar a confiabilidade dos dados e das informações contra qualquer
        tipo de alteração, seja de forma acidental ou fraudulenta.
        <br />
        <br />
        <div className="terms-and-conditions-title3">Disponibilidade </div>
        Estabelecer os controles necessários para que as informações estejam
        disponíveis para serem acessadas quando necessário.
        <br />
        <br />
        <div className="terms-and-conditions-title3">Auditabilidade </div>
        Propiciar que qualquer ação ou transação possa ser relacionada
        univocamente, garantindo o cumprimento dos controles fundamentais
        estabelecidos nas respectivas normas.
        <br />
        <br />
        Através desta Política, tornamos transparentes os dados que coletamos,
        as finalidades da coleta e os compartilhamentos realizados na operação
        do <b>Serviço LITECAMPUS</b>.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          Quais dados são coletados e porque coletamos?
        </div>
        Por dados pessoais entende-se, também no <b>"Serviço LITECAMPUS"</b>,
        qualquer informação que, isoladamente ou em conjunto com outras
        informações, possa ser utilizada para identificar uma pessoa singular.
        <br />
        <br />
        Os dados pessoais podem ser fornecidos diretamente por você quando usar
        sites, produtos ou serviços do <b>"Serviço LITECAMPUS"</b>, quando
        interagir com a plataforma, por exemplo, quando criar uma conta ou
        entrar em contato conosco para obter suporte.
        <br />
        <br />É possível, ainda, que o "Serviço LITECAMPUS" obtenha esses dados,
        gravando-os quando você interagir com nosso site, produtos ou serviços,
        usando tecnologias como cookies e recebendo dados de uso de software em
        execução nos seus dispositivos.
        <br />
        <br />
        No limite permitido em lei, é possível que obtenhamos esses dados de
        fontes públicas e comerciais de terceiros.
        <br />
        <br />
        Eventualmente, podemos comprar dados estatísticos de outras empresas
        para oferecer suporte ao <b>Serviço LITECAMPUS</b>. Os dados pessoais
        que forem coletados com base nesses autorizativos legais, podem incluir
        nome, endereços postais e de e-mail, número de telefone e informações de
        login (conta e senha), dependendo do site que você visita ou do produto
        e serviço que você usa.
        <br />
        <br />
        Para prover, evoluir e proteger o <b>Serviço LITECAMPUS</b> necessitamos
        de algumas informações suas, que podem ser coletadas de duas maneiras:
        <br />
        <br />
        <div
          className="terms-and-conditions-title3"
          style={{ marginBottom: 3 + "vh" }}
        >
          Informações que você provê para nós
        </div>
        <b>Informações da conta</b> – No primeiro login de acesso ao{" "}
        <b>Serviço LITECAMPUS</b>, nós coletamos seu nome, e-mail, identificador
        único na CAFe e instituição de origem, estes dados são utilizados para
        associar você a sua conta no <b>Serviço LITECAMPUS</b>.
        <br />
        <br />
        <b>Seu Telefone</b> – É obrigatório disponibilizar um contato
        telefônico.
        <br />
        <br />
        <b>Seu CPF</b> – No primeiro login é necessário informar seu Cadastro de
        Pessoa Física (CPF), se esta informação não for disponibilizada
        automaticamente pelos atributos da CAFe em sua autenticação federada.
        <br />
        <br />
        <b>Suas assinaturas</b> – O <b>Serviço LITECAMPUS</b> foi projetado para
        simplificar a aquisição por meio digital, o acesso e a gestão dos
        serviços que RNP e seus parceiros ofertam através do marketplace.
        <br />
        <br />
        <b>Informações de uso</b> – Nós coletamos informações relacionadas a
        como você usa o <b>Serviço LITECAMPUS</b>, incluindo ações que realiza
        em sua conta (como assinar um novo serviço, auto atribui um serviço
        assinado, solicita acesso a um serviço do catálogo). Usamos essas
        informações para melhorar sua experiência no <b>Serviço LITECAMPUS</b>
        , desenvolver novas funcionalidades e proteger os usuários.
        <br />
        <br />
        <b>Informações do dispositivo</b> – Se você acessa o Serviço LITECAMPUS
        nós coletamos informações sobre este dispositivo, incluindo seu endereço
        IP, localização, o tipo do dispositivo (Desktop, Tablets e Smartphone) e
        o sistema operacional do dispositivo.
        <br />
        <br />
        <b>Informações de Logs</b> – Nós registramos informações sobre você
        quando você acessa e usa o <b>Serviço LITECAMPUS</b> incluindo seu
        endereço IP (Internet Protocol), quantidade de acessos, último acesso,
        tipo do navegador ou dispositivo, idioma e a página web que você visitou
        antes de acessar o <b>Serviço LITECAMPUS</b>.
        <br />
        <br />
        <b>Informações de Cookies ou outras tecnologias</b> – Nós utilizamos
        diversas tecnologias para coletar informações relativas ao uso do{" "}
        <b>Serviço LITECAMPUS</b>, uma destas tecnologias são os cookies, que
        são pequenos arquivos de dados que são armazenados em seu dispositivo
        que nos ajuda a melhorar sua experiência no <b>Serviço LITECAMPUS</b>,
        como por exemplo, evitar que você precise se autenticar constantemente
        no <b>Serviço LITECAMPUS</b> e contar visitas únicas. Além disso, usamos
        outras tecnologias, como Google Analytics para gerar indicadores de uso
        do <b>Serviço LITECAMPUS</b>.
        <br />
        <br />
        Usamos estas informações para melhorar sua experiência no{" "}
        <b>Serviço LITECAMPUS</b>, desenvolver novas funcionalidades e proteger
        nossos usuários.
        <br />
        <br />
        <div className="terms-and-conditions-title2">
          Como acessar e controlar dados pessoais{" "}
        </div>
        É de sua responsabilidade garantir que todos os dados pessoais enviados
        à plataforma estejam corretos. A plataforma se dedicará a manter a
        exatidão e a integridade dos dados pessoais e a manter os dados
        atualizados.
        <br />
        <br />
        Na medida do exigido pela lei da sua jurisdição, como usuário você
        poderá:
        <br />
        <br />
        <ol type="a" className="terms-and-conditions-bullets">
          <li>
            ter o direito de acessar determinados dados pessoais que mantemos
            sobre você,
          </li>
          <li>
            solicitar que atualizemos ou corrijamos imprecisões nesses dados,
          </li>
          <li>objetar a nossa utilização dos seus dados pessoais, e</li>
          <li>
            pedir-nos para eliminar os seus dados pessoais do nosso banco de
            dados.
          </li>
        </ol>
        <br />
        Nessa situação (d), sua solicitação por escrito poderá ser necessária
        para a segurança, e nos será permitido recusar o pedido se tivermos
        motivos razoáveis para acreditar que o pedido é uma fraude, inviável ou
        pode comprometer a privacidade dos outros.
        <br />
        <br />
        <div className="terms-and-conditions-title2">
          Como utilizamos as informações pessoais?{" "}
        </div>
        Como utilizamos suas informações pessoais para operar, fornecer e
        melhorar os Ofertas da RNP.
        <br />
        <br />O <b>"Serviço LITECAMPUS"</b> pode vir a utilizar esses dados, com
        sua autorização, com as seguintes finalidades taxativas:
        <br />
        <br />
        <ol type="a" className="terms-and-conditions-bullets">
          <li>
            cumprir ordens do usuário; entregar, ativar ou verificar produtos ou
            serviços; cumprir com pedidos de mudanças dos usuários; e fornece
            suporte técnico;
          </li>
          <li>
            contatá-lo com o seu consentimento; enviar-lhe informações sobre os
            produtos e serviços que possam interessá-lo; convidá-lo a participar
            de atividades promocionais e pesquisas de mercado do "Serviço
            LITECAMPUS";
          </li>
          <li>
            enviar informações de marketing pontuais dos serviços integrantes da
            plataforma ou da RNP;
            <ol type="i" className="terms-and-conditions-bullets">
              <li>
                Sempre que você não quiser receber esse tipo de informação, pode
                optar por isso a qualquer momento, de forma facilitada na
                própria plataforma.
              </li>
            </ol>
          </li>
          <li>
            enviar avisos importantes, tais como atualizações e instalação de
            sistemas operacionais ou aplicativos, inoperâncias temporárias de
            sistemas ou manutenções programadas;
          </li>
          <li>
            fornecer experiência e conteúdo personalizado ao usuário; ativação
            de serviços pós-venda;
          </li>
          <li>
            aprimorar nossos produtos e serviços, por meio de auditoria interna,
            análise de dados estatístico e pesquisa estritamente relacionados ao
            objetivo do <b>"Serviço LITECAMPUS"</b>;
          </li>
          <li>
            analisar a eficiência das operações comerciais e avaliar as cotas de
            mercado;
          </li>
          <li>
            solucionar problemas quando você optar pelo envio ao
            <b>"Serviço LITECAMPUS"</b> de relatórios de erros;
          </li>
          <li>
            sincronizar, compartilhar e armazenar os dados carregados ou
            baixados e os dados estritamente necessários para o upload e o
            download;
          </li>
          <li>
            melhorar os nossos programas voltados ao aprimoramento dos serviços
            em prol da educação, pesquisa e inovação brasileiras;
          </li>
          <li>
            melhorar os nossos programas voltados ao aprimoramento de prevenção
            de perdas e antifraude;
          </li>
          <li>
            cumprir e aplicar os requisitos legais aplicáveis, padrões
            relevantes da indústria e políticas da RNP.
          </li>
        </ol>
        <br />
        Também poderá o <b>"Serviço LITECAMPUS"</b> coletar e usar informações
        não identificáveis pessoalmente (Não-PII).
        <br />
        <br />
        A não-PII é uma espécie de informação que não pode ser usada para
        identificar um indivíduo em particular. Por exemplo, a plataforma irá
        coletar dados estatísticos, como o número de visitantes de seu website.
        Nesse contexto, esses dados são recolhidos e utilizados para entender o
        padrão de uso dos produtos e serviços, sempre com o intuito de melhorar
        os serviços.
        <br />
        <br />
        Com base nesta política, o <b>"Serviço LITECAMPUS"</b> poderá coletar,
        usar, processar, transferir ou divulgar informações não-PII para outros
        fins, a seu próprio critério, com o cuidado de isolar os dados pessoais
        de não-PII e garantir o uso separado dos dois tipos de dados.
        <br />
        <br />
        Por fim, se os dados pessoais forem combinados com não-PII, ainda serão
        tratados pelo <b>"Serviço LITECAMPUS"</b> como dados pessoais durante o
        processamento.
        <br />
        <br />
        <div className="terms-and-conditions-title2">
          Com quem compartilhamos os dados?
        </div>
        Podemos compartilhar informações conforme detalhado neste parágrafo, mas
        jamais suas informações serão fornecidas a terceiros sem o objetivo de
        garantir o bom funcionamento do <b>Serviço LITECAMPUS</b>.
        <br />
        <br />
        <div className="terms-and-conditions-title3">Terceiros </div>
        Nós podemos compartilhar suas informações com terceiros que trabalham em
        nome da RNP, e especificamente com o <b>"Serviço LITECAMPUS"</b>, para o
        desenvolvimento, operação, manutenção e suporte especializado, sempre
        com o objetivo de ajudar a fornecer, melhorar e proteger o{" "}
        <b>"Serviço LITECAMPUS"</b>. Todas as atividades ou intervenções
        realizadas por terceiros em nome da RNP estão de acordo com esta
        Política de privacidade, sendo que todas as ações são acompanhadas e
        coordenadas de acordo com as nossas instruções. Estes terceiros são:
        <br />
        <br />
        <ol className="terms-and-conditions-bullets">
          <li>
            LiteMe Inteligência Energética na Gestão de Consumo de Energia
            Elétrica LTDA (CNPJ 29.740/161/0001-01)
          </li>
          <li>
            Smartiks Tecnologia da Informação LTDA (CNPJ 06.141.653/0001-77)
          </li>
        </ol>
        <br />
        <div className="terms-and-conditions-title3">
          Administradores institucionais{" "}
        </div>
        O administrador institucional possui acesso às informações de registro
        de sua conta (como nome, identificador único da CAFe, serviços
        assinados, consumo de recursos e departamentos), além disso, ele pode
        acessar e controlar a sua conta. Em caso de dúvidas a esse respeito,
        consulte as políticas internas de sua instituição.
        <br />
        <br />
        <div className="terms-and-conditions-title3">Obrigações legais </div>A
        proteção dos seus dados é fundamental para a RNP, acreditamos que os
        dados de nossos usuários devem receber as mesmas proteções legais,
        independentemente de estarem armazenados no <b>"Serviço LITECAMPUS"</b>{" "}
        ou em seus dispositivos pessoais. Porém, nós podemos julgar necessário o
        compartilhamento de suas informações a um terceiro:
        <br />
        <br />
        <ol type="a" className="terms-and-conditions-bullets">
          <li>
            Acreditarmos que é imprescindível para cumprir com as leis e
            regulamentações em vigor, processos legais ou solicitações
            apropriadas do governo;
          </li>
          <li>Para garantir o cumprimento dos nossos Termos e Políticas;</li>
          <li>
            Para garantir a segurança e integridade de nossos usuários, clientes
            e do <b>Serviço LITECAMPUS</b>;
          </li>
          <li>
            Para evitar o abuso do <b>Serviço LITECAMPUS</b> e violações de
            direitos autorais;
          </li>
          <li>
            Em solicitações de emergência, em que acreditarmos que o
            compartilhamento poderá ajudar a evitar danos físicos ou morte de
            qualquer pessoa;
          </li>
          <li>
            Com quaisquer outros terceiros, desde que seja autorizado
            expressamente por você;
          </li>
          <li>
            Quaisquer solicitações para obtenção de dados de nossos usuários por
            entidades do governo, forças policiais (incluindo solicitações de
            segurança nacional) ou outras entidades, serão analisados e
            respondidos com ética e transparência.
          </li>
        </ol>
        <br />
        <div className="terms-and-conditions-title2">
          Como tratamos os dados?
        </div>
        <div className="terms-and-conditions-title3"> Segurança </div>
        A segurança dos seus dados pessoais é da maior importância para a RNP.
        Para tanto, utilizamos medidas físicas, de gestão e técnicas adequadas
        para proteger os seus dados pessoais contra acesso não autorizado,
        divulgação, utilização, modificação, danos ou perdas.
        <br />
        <br />
        A plataforma utiliza tecnologias criptográficas para a confidencialidade
        dos dados, mecanismos de proteção para evitar ataques e mecanismos de
        controle de acesso que permitem apenas acesso autorizado a seus dados
        pessoais. Também fornecemos constantemente treinamento sobre segurança e
        proteção de privacidade para que os funcionários envolvidos na
        plataforma possam conscientizar-se sobre a proteção de dados pessoais.
        <br />
        <br />
        Garantimos que seus dados pessoais serão usados nos limites desta
        política e apenas quando necessário, a menos que de outra forma exigido
        por lei ou necessidade.
        <br />
        <br />
        A RNP está constantemente empenhada em proteger os seus dados pessoais,
        apesar de sabermos que nenhuma medida de segurança é perfeita, razão
        pela qual recomendamos que você jamais compartilhe a sua senha com
        outras pessoas, pois isso pode colocar sua segurança e seus dados
        pessoais em risco.
        <br />
        <br />
        <div className="terms-and-conditions-title3">
          {" "}
          Controles do usuário{" "}
        </div>
        Você pode visualizar suas informações pessoais acessando a página de
        configurações do seu perfil no Portal de Serviços. Como suas informações
        são providas pela instituição a qual você está vinculada na CAFe, para
        alterar estas informações, você deve entrar em contato com o
        administrador local de sua instituição. Caso você deseje excluir suas
        informações do <b>Serviço LITECAMPUS</b> é necessário encerrar a sua
        conta, mas note que ao encerrar a sua conta todos os acessos aos
        serviços assinados serão removidos do <b>Serviço LITECAMPUS</b>, ficando
        sob sua responsabilidade o acesso fora do <b>Serviço LITECAMPUS</b>{" "}
        mesmos antes de encerrar a conta.
        <br />
        <br />
        <div className="terms-and-conditions-title3">
          {" "}
          Transferências Internacionais de Dados Pessoais{" "}
        </div>
        Apesar de a RNP utilizar uma tecnologia desenvolvida por uma empresa que
        não estálocalizada no Brasil na plataforma <b>"Serviço LITECAMPUS"</b>,
        seus dados pessoais coletados não serão processados em outro país,
        exceto quando os sensores utilizados possuam alguma integração com
        serviços de terceiros. Neste caso, o <b>"Serviço LITECAMPUS"</b>{" "}
        consumirá os dados deste serviço e os processará no Brasil, porém, sem
        controle de como é ele usado pelo seu prestador de serviço.
        <br />
        <br />
        <div className="terms-and-conditions-title3">
          {" "}
          Suporte e atendimento{" "}
        </div>
        <b>Manutenção</b> - Enquanto sua conta não for encerrada, nós iremos
        manter as informações que você armazena no Serviço{" "}
        <b>"Serviço LITECAMPUS"</b>, porém, podem haver casos em que há
        necessidade de manter seus dados mesmo após o encerramento de sua conta,
        como, cumprir com nossas obrigações legais, resolver litígios ou fazer
        cumprir nossos Termos e Políticas.
        <br />
        <br />
        <div className="terms-and-conditions-title2">
          {" "}
          As relações dos dados pessoais com parceiros do "Serviço LITECAMPUS",
          seus produtos e serviços{" "}
        </div>
        Para garantir a melhor experiência possível no Serviço LITECAMPUS", você
        poderá receber conteúdo ou links da Web de terceiros que não sejam da
        RNP e seus parceiros ("terceiros"). A RNP não tem o direito de controlar
        tais terceiros, mas você pode optar por usar os links, ver o conteúdo e
        / ou acessar os produtos ou serviços fornecidos por terceiros, inclusive
        optar por não mais receber.
        <br />
        <br />
        À RNP não compete controlar as práticas de privacidade e as políticas de
        proteção de dados de terceiros, que não estão sujeitos a esta Política.
        Assim, antes de porventura você enviar informações pessoais a terceiros,
        leia e consulte a política de proteção de privacidade do terceiro.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Lei aplicável </div>
        Estas <b>Políticas</b> são regidas pelas leis Brasileiras.
        <br />
        <br />
        <div className="terms-and-conditions-title2">
          {" "}
          Modificações nos termos{" "}
        </div>
        Os Termos de uso podem ser revisados de tempos em tempos para melhor
        refletir:
        <br />
        <br />
        <ol className="terms-and-conditions-bullets">
          <li>Mudanças na legislação</li>
          <li>Novos requisitos regulamentares;</li>
          <li>
            Melhorias ou aprimoramentos realizados no <b>Serviço LITECAMPUS</b>.
          </li>
        </ol>
        <br />
        Caso uma melhoria afete os <b>Termos</b> e/ou <b>Políticas</b> do{" "}
        <b>Serviço LITECAMPUS</b> ou seus direitos como usuário, notificaremos
        você, antes da data de atualização efetiva dos <b>Termos</b> e/ou{" "}
        <b>Políticas</b>, enviando um e-mail ao endereço associado à sua conta
        ou enviando uma notificação através do Serviço. Os termos atualizados
        entrarão em vigor no prazo mínimo de 30 dias após a notificação.
        <br />
        <br />
        Caso não concorde com os novos <b>Termos</b> e/ou <b>Políticas</b>, você
        pode encerrar sua conta antes da entrada em vigor dos mesmos. Caso você
        continue a utilizar o Serviço após os novos <b>Termos</b> e/ou{" "}
        <b>Políticas</b> entrarem em vigor, fica entendido que você concorda em
        seguir os <b>Termos</b> e/ou <b>Políticas</b> atualizados.
        <br />
        <br />
        Os <b>Termos</b> e/ou <b>Políticas</b> atualizados estarão disponíveis
        em: https://Produto/ServiçodoGT-LITECAMPUS.rnp.br/termos.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Outros </div>
        <div className="terms-and-conditions-title2">
          {" "}
          Crianças e adolescentes{" "}
        </div>
        O <b>Serviço LITECAMPUS</b> não é direcionado para menores de 18 anos,
        caso seja identificado o uso por pessoas com menos de 18 anos, serão
        tomadas as devidas providências para o encerramento de sua conta e
        remoção de todos os seus dados.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Como nos contatar </div>
        Sugestões ou dúvidas relacionadas a estas Políticas? Entre em contato
        com nosso responsável pela proteção de dados da RNP em
        privacidade@rnp.br ou escreva para nós no seguinte endereço:
        <br />
        <br />
        Rede Nacional de Ensino e Pesquisa – RNP
        <br />
        BRASÍLIA
        <br />
        SAS, quadra 5, lote 6, bloco H
        <br />
        7° andar - Edifício IBICT
        <br />
        Brasília - DF - 70070-914
        <br />
        <br />
        Você pode enviar mensagens para o nosso WhatsApp, o número é 0800 722
        0216.
        <br />
        Para urgências ligue para 0800 722 0216.
        <br />
        Atendimento 24 horas, todos os dias.
        <br />
        <br />
        Se você estiver ciente de qualquer vulnerabilidade ou ameaça, que possa
        afetar a segurança do <b>"Serviço LITECAMPUS"</b>, ou mesmo se quiser
        informações a este respeito, entre em contato com nossa equipe de
        Resposta a Incidentes de Segurança (CSIRT RNP), por meio do e-mail:
        cais@cais.rnp.br
      </div>
      <Footer />
    </div>
  );
};

export default GeneralPrivacy;
