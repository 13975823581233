import React from "react";
import "./HomeHeader.scss";
import { Row } from "react-bootstrap";

const HomeHeader = () => {
  return (
    <Row className="page-section home-header" id="home-header-element-div">
      <div className="home-header-img"></div>
      <div className="home-header-text-wrapper">
        <div className="home-header-rect"></div>
        <div className="home-header-text">
          Inteligência energética
          <br />
          que gera valor
          <div className="home-header-subtext">
            Entenda onde e quando estão os
            <br />
            maiores consumos e identifique
            <br />
            oportunidades de economia de
            <br />
            energia!
          </div>
        </div>
      </div>
    </Row>
  );
};

export default HomeHeader;
