const CONSUMPTION_VALUES = [243.36, 309.61, 539.99, 1187.96, 805.69];
const AVERAGE = (CONSUMPTION_VALUES.reduce((x, y) => x + y) / 5).toFixed(2);

const LAST_HOUR_AVERAGE = (AVERAGE / 30 / 24).toFixed(2);

export const getActualBill = async id => {
  return { consumption: +CONSUMPTION_VALUES[id] };
};

export const getConsumptionCompare = async id => {
  const ACTUAL = (CONSUMPTION_VALUES[id] / 30 / 24).toFixed(2);
  return { actual: +ACTUAL, average: +LAST_HOUR_AVERAGE };
};
