import React from "react";
import classNames from "classnames";

import "./HexBar.scss";

const HexBar = ({ height, type }) => {
  return (
    <div
      style={{ height: height + "px" }}
      className={classNames(["hexbar", type])}
    ></div>
  );
};

export default HexBar;
