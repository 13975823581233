import React, { useState } from "react";
import { Navbar, Nav, Col, NavDropdown } from "react-bootstrap";
import { NavBarConfig } from "../../data/NavBarConfig";
import "./Navbar.scss";

const Navigator = () => {

  const [activeTab] = useState(window.location.pathname.replace("/", ""));

  return (
    <Navbar collapseOnSelect expand="lg" className="nav">
      <Navbar.Brand href="/" className="logo"></Navbar.Brand>
      <Col className="toggle-button-navbar">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Col>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {
            NavBarConfig.app.map((link, i) => {
              if (NavBarConfig[link.value]) {
                return (
                  <div key={i}>
                    <Nav.Link href={"/" + link.value} className={ activeTab === link.value ? 'inner-nav-link-desktop  active-tab' : 'inner-nav-link-desktop' }>
                      {link.label}
                    </Nav.Link>
                    <NavDropdown title={link.label} className={ activeTab === link.value ? 'inner-nav-link-mobile active-tab' : 'inner-nav-link-mobile' }>
                      { NavBarConfig[link.value].map((item, j) => (
                        <NavDropdown.Item key={j} href={ "/" + link.value + "/" + item.value }> { item.label } </NavDropdown.Item>
                        )) 
                      }
                    </NavDropdown>
                  </div>
                )
              } else {
                return (
                  <Nav.Link key={i} href={"/" + link.value} className={ activeTab === link.value ? 'active-tab' : '' }>
                    {link.label}
                  </Nav.Link>
                )
              }
            })
          }
          <Nav.Link key='painel-login' href='https://painel.liteme.com.br/#/login'>
            Login
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigator;
