import React from "react";
import { Row, Col } from "react-bootstrap";
import "./ResidenceDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import GenerationSection from "./GenerationSection/GenerationSection";
import InnerFooter from "../../InnerFooter/InnerFooter";

const ResidenceDashboard = () => {

  return (
    <div>
      <div id="residence-header-element-div">
        <DashboardsHeader dashboardStyle="residence"/>

        <Row className="residence-initial-text">
          <Col xs={10} sm={7} className="residence-initial-text-col">
          O LiteMe pode ser usado para dar um maior controle a sua conta, identificando os aparelhos 
          que possuem o maior consumo de energia e definindo metas e limites de custo.
          </Col>
        </Row>

        <Row className="residence-section residence-boxes-section">
          <Col xs={12} md={6} className="residence-two-columns-section-img residence-boxes-section">
          </Col>

          <Col xs={12} md={6} className="residence-two-columns-section-text">
            <div className="residence-two-columns-section-text-wrapper">
              <div className="residence-two-columns-section-header"> 
                Custo e consumo em tempo real, além da previsão de conta mensal
              </div>
              <br/>
              Acompanhamento em tempo real de informações sobre consumo e custo. 
              Com base no seu padrão de consumo, o LiteMe estima o valor da sua conta de energia mensal.
            </div>
          </Col>
        </Row>
      </div>

      <Row className="residence-section devices-section" id="residence-devices-element-div">
        <Col xs={12} md={6} className="residence-two-columns-section-text devices-section">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header"> 
              Consumo dos principais aparelhos
            </div>
            <br/>
            Algoritmos soﬁsticados baseados em técnicas avançadas de Inteligência Artiﬁcial permitem estimar o consumo dos 
            principais equipamentos sem que seja necessário colocar um medidor em cada equipamento.
          </div>
        </Col>

        <Col xs={12} md={6} className="residence-two-columns-section-img devices-section">
        </Col>
      </Row>

      <Row className="residence-section always-on-section" id="residence-always-on-element-div">
        <Col xs={12} md={6} className="residence-two-columns-section-img always-on-section">
        </Col>

        <Col xs={12} md={6} className="residence-two-columns-section-text always-on-section">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header"> 
              Consumo sempre ligado
            </div>
            <br/>
            Apresenta o consumo que nunca desliga. Em geral, este consumo é representado por refrigeradores e aparelhos ﬁcam em 'stand-by'.
            <br/><br/> 
            Estima-se que, em média, este consumo seja aproximadamente 10% do consumo total de uma residência.
          </div>
        </Col>
      </Row>

      <Row className="residence-section efficiency-section" id="residence-efficiency-element-div">
        <Col xs={12} md={6} className="residence-two-columns-section-text efficiency-section">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header"> 
              Eficientímetro
            </div>
            <br/>
            Informa se o consumo agora está maior ou menor em relação ao histórico de consumo. 
            O gráﬁco no formato de um 'velocímetro' indica o quanto o consumo está maior ou menor.
          </div>
        </Col>

        <Col xs={12} md={6} className="residence-two-columns-section-img efficiency-section">
        </Col>
      </Row>

      <Row className="residence-section period-consumption-section" id="residence-period-consumption-element-div">
        <Col xs={12} md={6} className="residence-two-columns-section-img period-consumption-section">
        </Col>

        <Col xs={12} md={6} className="residence-two-columns-section-text period-consumption-section">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header"> 
              Consumo por período
            </div>
            <br/>
            Permite entender em que turno ocorre o maior consumo e quanto ele representa.
          </div>
        </Col>
      </Row>

      <Row className="residence-section recommendations-section" id="residence-recommendations-element-div">
        <Col xs={12} md={6} className="residence-two-columns-section-text recommendations-section">
          <div className="residence-two-columns-section-text-wrapper">
            <div className="residence-two-columns-section-header"> 
              Recomendações e Alertas
            </div>
            <br/>
            O LiteMe monitora continuamente o consumo, identifica padrões, emite alertas e gera recomendações 
            destacando oportunidade de economia.
          </div>
        </Col>

        <Col xs={12} md={6} className="residence-two-columns-section-img recommendations-section">
        </Col>
      </Row>

      <GenerationSection />

      <InnerFooter firstLine={"Transforme sua"} secondLine={"RESIDÊNCIA"} innerFooterStyle="residence"/>
    </div>
  );
};

export default (ResidenceDashboard);
