import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Partners.scss";

const Partners = () => {
  return (
    <div className="partners-container">
      <Row className="partners-row">
        <div className="partners-artwork-wrapper">
          <img
            className="partners-artwork"
            src={require("../../../assets/img/partners/partners-artwork.svg")}
            alt="partners-artwork"
          />
        </div>
      </Row>
      <Row className="partners-row">
        <div className="partners-list-header">PARCEIROS</div>
      </Row>
      <Row className="partners-list">
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/bnb.png")}
            alt="bnb"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/embrapii.png")}
            alt="embrapii"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/fapesqpb.png")}
            alt="fapesqpb"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/finep.png")}
            alt="finep"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/lsd.png")}
            alt="lsd"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/neoenergia.png")}
            alt="neoenergia"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/paqtcpb.png")}
            alt="paqtcpb"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/rnp.png")}
            alt="rnp"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/sebrae.png")}
            alt="sebrae"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/senai.png")}
            alt="senai"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner tradenergy"
            src={require("../../../assets/img/partners/tradenergy.png")}
            alt="trade"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/ufcg.png")}
            alt="ufcg"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/yamatec.png")}
            alt="yamatec"
          />
        </Col>
      </Row>

      <Row className="partners-row">
        <div className="partners-list-header">IMPLANTAÇÕES RECENTES</div>
      </Row>
      <Row className="partners-list">
        <Col sm={12}>
          <img
            className="img-partner pmcg"
            src={require("../../../assets/img/partners/pmcg.svg")}
            alt="pmcg"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/bomqueso.png")}
            alt="bomqueso"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/duraplast.png")}
            alt="duraplast"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/polpa.png")}
            alt="polpa"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/redecompras.png")}
            alt="redecompras"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/ssmoveis.png")}
            alt="ssmoveis"
          />
        </Col>
        <Col sm={6} md={4}>
          <img
            className="img-partner"
            src={require("../../../assets/img/partners/subway.png")}
            alt="subway"
          />
        </Col>
        <Col md={3}>
          <img
            className="img-partner univesity"
            src={require("../../../assets/img/partners/uepb.png")}
            alt="uepb"
          />
        </Col>
        <Col md={3}>
          <img
            className="img-partner univesity"
            src={require("../../../assets/img/partners/ufpb.png")}
            alt="ufpb"
          />
        </Col>
        <Col md={3}>
          <img
            className="img-partner univesity"
            src={require("../../../assets/img/partners/ufrpe.png")}
            alt="ufrpe"
          />
        </Col>
        <Col md={3}>
          <img
            className="img-partner univesity"
            src={require("../../../assets/img/partners/ufsc.png")}
            alt="ufsc"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Partners;
