import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "./BlockModal.scss";
import Bars from "../Bars/Bars";

export default class BlockModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { type: "energy" };
  }

  changeType = type => {
    this.setState({ ...this.state, type: type });
  };

  getBlockName = name => {
    return name ? name.split("-")[0] : "";
  };

  getBlockSubtitle = name => {
    let names = name ? name.split("-") : [""];
    return names.length > 1 ? names[1] : " ";
  };

  render() {
    let startDate = moment()
      .millisecond(0)
      .seconds(0)
      .minute(0);
    let end = startDate.hour();
    let start = startDate.subtract(1, "hours").hour();

    let { type } = this.state;
    let {
      loading,
      blockName,
      energyConsumption,
      compareData,
      day,
      month,
      onCloseClick
    } = this.props;
    return (
      <div className="block-modal-container">
        <div className={"block-modal-header " + type}>
          <div className="block-modal-header-container">
            <div className="change-type-container">
              <div
                onClick={() => this.changeType("energy")}
                className={
                  "change-type-button change-type-button-energy " +
                  (type === "energy" ? "change-type-active" : "")
                }
              ></div>
              <div
                onClick={() => this.changeType("water")}
                className={
                  "change-type-button change-type-button-water " +
                  (type === "water" ? "change-type-active" : "")
                }
              ></div>
            </div>
            <span className="block-modal-header-title">
              {type === "energy" && "ENERGIA"}
              {type === "water" && "ÁGUA"}
            </span>
            <div className="close-button-container" onClick={onCloseClick}>
              <div className="close-button-image"></div>
            </div>
          </div>
        </div>
        <div className="block-modal-body">
          <div className="block-name-container">
            <div className="block-modal-body-title">
              {this.getBlockName(blockName)}
            </div>
            <div className="block-modal-body-subtitle">
              {this.getBlockSubtitle(blockName)}
            </div>
          </div>
          <div className="block-data-container">
            <div className="block-data-column">
              <div className={"block-icon-" + type}></div>
              <div className="block-icon-legend">
                <div className={"block-icon-month-" + type}>
                  {day}/{month}
                </div>
                <div className="block-icon-year">Dia</div>
              </div>
            </div>
            <div className="block-data-column block-bars-column">
              <Bars
                daily={type === "energy" ? compareData.actual : 120.5}
                average={type === "energy" ? compareData.average : 141.6}
                type={type}
              />
              <div className="block-icon-legend">
                <div className={"block-icon-month-" + type}>
                  Comparação<br></br>de Consumo{" "}
                  <span className="block-data-unit">
                    {type === "energy" ? "(kWh)" : "(L)"}
                  </span>
                </div>
                <div className="block-icon-year">
                  {loading ? (
                    "Carregando"
                  ) : (
                    <div>
                      {start}h - {end}h
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="block-data-column">
              <div className={"block-data-" + type}>
                <div className="block-data-value">
                  {loading
                    ? "Carregando"
                    : type === "energy"
                    ? energyConsumption
                    : 96.2}
                </div>
              </div>
              <div className="block-icon-legend">
                <div className={"block-icon-month-" + type}>Consumo mensal</div>
                <div className="block-icon-year">
                  <span className="block-data-unit">
                    {type === "energy" ? "(kWh)" : "(m³)"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlockModal.propTypes = {
  loading: PropTypes.bool,
  blockName: PropTypes.string,
  energyConsumption: PropTypes.number,
  waterConsumption: PropTypes.number,
  day: PropTypes.number,
  month: PropTypes.number
};
