import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <Row className="footer-logo-wrapper">
        <Col xs={12} sm={6} md={4} lg={3} className="footer-logo-col liteme">
          <img
            className="footer-logo"
            src={require("../../assets/img/logo/liteme/white/horizontal/logo.svg")}
            alt="smartiks"
          />
          <div className="footer-logo-question">Precisa de ajuda?</div>
          <div className="footer-logo-answer">Fala com a gente!</div>
          <div className="footer-logo-contact">
            <a
              href="https://api.whatsapp.com/send?phone=5583998611159"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="contact-icon"
                icon={faWhatsapp}
                size="1x"
              />
              83 99861-1159
            </a>
          </div>
          <div className="footer-logo-contact">
            <a
              href="mailto:contato@liteme.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="contact-icon"
                icon={faEnvelope}
                size="1x"
              />
              contato@liteme.com.br
            </a>
          </div>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3} className="footer-logo-col smartiks">
          <img
            className="footer-logo smartiks"
            src={require("../../assets/img/partners/smartiks.svg")}
            alt="smartiks"
          />
          <div className="footer-logo-sub-text">
            LiteMe, uma empresa do Grupo Smartiks
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
