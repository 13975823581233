import React, { Component } from "react";
import { Row } from "react-bootstrap";
import "./MapSection.scss";
import { MapContainer, GeoJSON, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import litecampus from "../../../data/litecampus.geo.json";
import centerOfMass from "@turf/center-of-mass";
import * as turf from "@turf/helpers";
import { getActualBill, getConsumptionCompare } from "../../../api/Service";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import BlockModal from "./BlockModal/BlockModal";
import LitecampusPin from "../../../assets/img/map/litecampus-pin.svg";
import SelectedPin from "../../../assets/img/map/selected-pin.svg";

const active = {
  weight: 0.5,
  color: "#F36C3F",
  fillColor: "#F36C3F"
};

const defaultStyle = {
  color: "#1D5E96",
  weight: 1,
  fillColor: "#1D5E96",
  fillOpacity: 1
};

const available = {
  color: "#1D5E96",
  weight: 1,
  fillColor: "#1D5E96",
  fillOpacity: 1
};

const newIcon = new L.icon({
  iconUrl: LitecampusPin,
  iconSize: [40, 40],
  iconAnchor: [20, 45]
});

const selectedIcon = new L.icon({
  iconUrl: SelectedPin,
  iconSize: [40, 40],
  iconAnchor: [20, 40]
});

class MapSection extends Component {
  constructor(props) {
    super(props);
    let date = new Date();

    let markers = [];

    litecampus.features.forEach(feature => {
      let center = centerOfMass(turf.polygon(this.getCoordinates(feature)));
      markers.push({
        pos: [center.geometry.coordinates[1], center.geometry.coordinates[0]],
        feature: feature
      });
    });

    this.state = {
      position: [-7.225493, -35.919182],
      marker: undefined,
      buildingMarkers: markers,
      zoom: 14,
      colorMode: "light",
      isHidden: true,
      day: date.getDate(),
      month: date.getMonth() + 1,
      data: {}
    };
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  fetchData = buildingId => {
    if (!buildingId) {
      this.setBuildingData(buildingId, 0, { actual: 0, average: 0 });
      return;
    }
    this.setBuildingLoading(buildingId, true);
    Promise.all([getActualBill(buildingId), getConsumptionCompare(buildingId)])
      .then(data => {
        this.setBuildingData(
          buildingId,
          parseFloat(data[0].consumption.toFixed(2)),
          data[1]
        );
      })
      .catch(err => {
        this.setState({
          ...this.state,
          compareData: { actual: 0, average: 0 }
        });
        this.setBuildingData(buildingId, 0, { actual: 0, average: 0 });
      });
  };

  getCoordinates = feature => {
    if (feature.geometry.type === "GeometryCollection") {
      const midBuildingIndex = Math.floor(
        feature.geometry.geometries.length / 2
      );
      return feature.geometry.geometries[midBuildingIndex].coordinates;
    } else {
      return feature.geometry.coordinates;
    }
  };

  componentDidMount() {
    let bounds = {
      x: 1297,
      y: 541
    };

    this.resizeTo(bounds);
    setTimeout(() => {
      this.pinClick(this.state.buildingMarkers[0]);
    }, 1000);
  }

  resizeTo = bounds => {
    if (bounds.x < 768) {
      this.setState({
        ...this.state,
        zoom: 14.8,
        position: [-7.213655812265496, -35.91303888667988]
      });
    } else {
      this.setState({
        ...this.state,
        position: [-7.213644084440359, -35.91370967285063],
        zoom: 16.6
      });
    }
  };

  fetchBuildingData = async buildingId => {
    this.fetchData(buildingId);
  };

  setBuildingData = (buildingId, dailyUsage, compareData) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [buildingId]: {
          ...this.state.data[buildingId],
          loading: false,
          dailyUsage: dailyUsage,
          compareData: compareData
        }
      }
    });
  };

  setBuildingLoading = (buildingId, loading) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [buildingId]: {
          ...this.state.data[buildingId],
          loading: loading
        }
      }
    });
  };

  pinClick = marker => {
    let feature = marker.feature;
    let pos = marker.pos;
    let hasAvailableData = feature.properties.buildingId !== "";

    this.setState({
      ...this.state,
      marker: pos
    });

    let buildingId = hasAvailableData
      ? feature.properties.buildingId
      : feature.properties.name;

    if (!this.state.data[buildingId]) {
      this.setState({
        data: {
          ...this.state.data,
          [buildingId]: {
            dailyUsage: 0,
            block: feature.properties.name,
            compareData: { actual: 0, average: 0 }
          }
        }
      });
    }

    this.fetchBuildingData(feature.properties.buildingId);
    this.setState({
      ...this.state,
      buildingId: buildingId,
      isHidden: false,
      compareData: { actual: 0, average: 0 }
    });
  };

  onEachFeature = (feature, layer) => {
    let hasAvailableData = feature.properties.buildingId !== "";

    layer.on("click", e => {
      this.setState({
        ...this.state,
        marker: [e.latlng.lat, e.latlng.lng]
      });

      let buildingId = hasAvailableData
        ? feature.properties.buildingId
        : feature.properties.name;

      if (!this.state.data[buildingId]) {
        this.setState({
          data: {
            ...this.state.data,
            [buildingId]: {
              dailyUsage: 0,
              block: feature.properties.name,
              compareData: { actual: 0, average: 0 }
            }
          }
        });
      }

      this.fetchBuildingData(feature.properties.buildingId);
      this.setState({
        ...this.state,
        buildingId: buildingId,
        isHidden: false,
        compareData: { actual: 0, average: 0 }
      });
    });

    hasAvailableData ? layer.setStyle(available) : layer.setStyle(defaultStyle);

    layer.on("mouseover", () => layer.setStyle(active));

    layer.on("mouseout", () => {
      hasAvailableData
        ? layer.setStyle(available)
        : layer.setStyle(defaultStyle);
    });
  };

  close = () => this.setState({ isHidden: true });

  onDrag = event => {
    // console.log(event.target.getCenter());
  };

  onResize = event => {
    this.resizeTo(event.newSize);
  };

  onClose = () => {
    this.setState({ ...this.state, buildingId: undefined, isHidden: true });
  };

  render() {
    return (
      <div className="map-section">
        <div className="map-container">
          {!this.state.isHidden && (
            <BlockModal
              loading={this.state.data[this.state.buildingId].loading}
              blockName={this.state.data[this.state.buildingId].block}
              energyConsumption={
                this.state.data[this.state.buildingId].dailyUsage
              }
              compareData={this.state.data[this.state.buildingId].compareData}
              day={this.state.day}
              month={this.state.month}
              onCloseClick={this.onClose}
            ></BlockModal>
          )}
          <MapContainer
            center={this.state.position}
            zoom={this.state.zoom}
            zoomSnap={1}
            trackResize={true}
            attributionControl={true}
            zoomControl={false}
            doubleClickZoom={false}
            scrollWheelZoom={false}
            dragging={true}
            animate={true}
            easeLinearity={0.35}
            onMoveEnd={this.onDrag.bind(this)}
            onResize={this.onResize.bind(this)}
          >
            <TileLayer
              url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmljdG9yYm9yZ2VzIiwiYSI6ImNrdGQybjJ2NjBlNmEyd3BmZHNmNmQyYm8ifQ.1E0DeLlBtSgRv0pdBggWew`}
            />
            {this.state.buildingMarkers &&
              this.state.buildingMarkers.map((m, i) => (
                <Marker
                  key={i}
                  position={m.pos}
                  icon={
                    this.state.buildingId === m.feature.properties.buildingId
                      ? selectedIcon
                      : newIcon
                  }
                  eventHandlers={{
                    click: e => {
                      this.pinClick(m);
                    }
                  }}
                ></Marker>
              ))}
            <GeoJSON
              data={litecampus}
              onEachFeature={this.onEachFeature.bind(this)}
            />
          </MapContainer>
        </div>
        <Row className="map-label-wrapper">
          <div className="map-label">
            Exemplo aplicado no município de
            <br />
            Campina Grande, no estado da Paraíba
          </div>
        </Row>
      </div>
    );
  }
}

export default withCookies(MapSection);
