import React from "react";
import "./BenefitsSection.scss";
import blocks from "../../../assets/img/home/benefits/blocks.svg";

const BenefitsSection = () => {
  return (
    <div className="benefits-section">
      <img alt="blocks" className="blocks" src={blocks} />
      <div className="benefits-container">
        <span className="benefits-title">Benefícios do LiteMe Maps</span>
        <div className="timeline">
          <div className="container start"></div>
          <div className="container right">
            <div className="content">
              <h2>IDENTIFICAÇÃO</h2>
              <p>
                Identifica onde e quando está gastando mais. Envia relatórios
                com análise detalhada, e monitora suas campanhas de
                racionamento.
              </p>
            </div>
          </div>
          <div className="container left">
            <div className="content">
              <h2>GERENCIAMENTO</h2>
              <p>
                Gerencia os hábitos de consumo dos locais, o contrato de demanda
                e fator de potência com a concessionária.
              </p>
            </div>
          </div>
          <div className="container right">
            <div className="content">
              <h2>NOTIFICAÇÕES</h2>
              <p>
                Notifica consumo atípico, levando em consideração o padrão de
                consumo para o horário. Envia as notificações por e-mail ou no
                seu celular.
              </p>
            </div>
          </div>
          <div className="container left">
            <div className="content">
              <h2>SEGURANÇA</h2>
              <p>Protege os dados coletados, garantindo sua privacidade.</p>
            </div>
          </div>
          <div className="container end"></div>
        </div>
        <div className="benefits-subheader">
          <span className="benefits-subtitle">ECONOMIA</span>
          <span className="benefits-text">
            Saiba como e quando tomar decisões.
          </span>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
