import React from "react";
import "./Join.scss";
import JoinForm from "./JoinForm/JoinForm"
import Footer from "./../Footer/Footer"

const Join = () => {

  const goToForm = () => {
    const formContainer = document.getElementById('join-form-element-div');
    formContainer.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="page-container" id="join-page-container">
      <div className="page-section join-header" id="join-header">
        <div className="join-header-txt">SEJA<br/>LITEME</div>
        <div href="#" className="arrow-container" onClick={() => goToForm()}>
          <div className="arrow"></div>
          <div className="arrow"></div>
          <div className="arrow"></div>  
        </div>
      </div>
      <JoinForm />
      <Footer />
    </div>
  );
};

export default (Join);
