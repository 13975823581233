import React from "react";
import "../../TermsAndConditions.scss";
import Footer from "../../../Footer/Footer";

const GeneralUsage = () => {
  return (
    <div className="terms-and-conditions-wrapper">
      <div className="terms-and-conditions-header2">
        Versão 1.0 (04 de dezembro de 2020)
      </div>
      <div className="terms-and-conditions-header">
        LiteCampus - Termos de Uso
      </div>
      <div className="terms-and-conditions-text">
        <div className="terms-and-conditions-title">1 Termos de Uso </div>
        Obrigado por escolher o <b>LiteCampus</b>! O presente termo de uso do
        serviço Serviço LiteCampus ("<b>Contrato</b>") contém os termos e as
        condições que regem seu acesso e o uso das ofertas de produtos e ou
        serviços.
        <br />
        <br />O Serviço LiteCampus possui um dashboard para gerência de consumo
        de energia, acompanhado dos sistemas de gerência de dados associados.
        <br />
        <br />O presente Contrato é firmado entre a Rede Nacional de Ensino e
        Pesquisa (<b>“RNP”</b>) e você ou a sua instituição que você representa
        ("<b>você</b>" ou "<b>suas</b>") para o uso do (
        <b>"Serviço do LITECAMPUS"</b>).
        <br />
        <br />
        O Contrato entrará em vigor assim que você clicar no botão ou na caixa
        de seleção “Eu Aceito”, que será apresentado com esses termos.
        <br />
        <br />
        Você declara que estar legalmente apto a celebrar contratos e se estiver
        representando uma instituição, como por exemplo uma Autarquia Federal,
        você declara ter autoridade legal para estar vinculado a ela.
        <br />
        <br />
        <div className="terms-and-conditions-title">2 Uso das Ofertas </div>
        Você pode acessar e usar as Ofertas de Serviço de acordo com este
        Contrato.
        <br />
        <br />
        Para acessar os serviços, você precisa ter uma conta no serviço Serviço
        LiteCampus associado à sua conta Federada (CAFe), que tem como atributo
        seu endereço de e-mail institucional, seguindoa Política de Uso deste
        serviço.
        <br />
        <br />
        <div className="terms-and-conditions-title">3 Ofertas do Serviço </div>
        A RNP pode alterar ou encerrar todas ou quaisquer Ofertas de Serviço, ou
        alterar ou remover funcionalidades de todas ou quaisquer Ofertas de
        Serviço periodicamente. Nós o notificaremos de qualquer mudança
        substancial ou encerramento das Ofertas de Serviço.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          4 Acordos de Nível de Serviço{" "}
        </div>
        Poderemos alterar, encerrar ou adicionar Acordos de Nível de Serviço
        periodicamente.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          5 Suas Responsabilidades{" "}
        </div>
        Exceto na medida em que for causado por um descumprimento deste Contrato
        pela RNP, você será responsável por todas as atividades desenvolvidas
        sob sua conta, independentemente se as atividades foram autorizadas ou
        desenvolvidas por você, seus empregados ou por um terceiro (incluindo
        seus contratados, agentes ou Usuários Finais), e a RNP e seus Parceiros
        não serão responsáveis por acessos não-autorizados à sua conta. Você é
        responsável pela configuração e utilização adequada das Ofertas de
        Serviço.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          6 Credenciais de Acesso e Senhas de Conta{" "}
        </div>
        As credenciais de acesso do Serviço LiteCampus e as senhas privadas
        geradas pelos serviços contratados são apenas para uso interno, sendo
        que você não irá vendê-las, transferi-las ou sublicenciá-las a qualquer
        outra entidade ou pessoa.
        <br />
        <br />
        <div className="terms-and-conditions-title">7 Usuários Finais </div>
        Você é responsável pelo uso dos seus dados e da Ofertas de Serviços por
        Usuários Finais. Você assegurará que todos os Usuários Finais observem
        suas obrigações nos termos do presente Contrato e que os termos do seu
        contrato com cada Usuário Final sejam condizentes com este Contrato. O
        suporte aos usuários finais será feito através dos canais de atendimento
        associados ao seu contrato.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          8 Suspensão Temporária{" "}
        </div>
        Poderemos suspender o seu direito ou o direito de qualquer Usuário Final
        de acesso ou uso de quaisquer parcelas ou de todas as Ofertas de Serviço
        imediatamente, mediante aviso, se estabelecermos que o uso das - Ofertas
        de Serviço por você ou por um Usuário Final:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Representa um risco de segurança às Ofertas de Serviço ou a qualquer
            terceiro;
          </li>
          <li>
            Possa vir a prejudicar nossos sistemas, as Ofertas de Serviço ou os
            sistemas ou o Conteúdo de qualquer outro cliente do Serviço
            LiteCampus;{" "}
          </li>
          <li>
            Possa acarretar responsabilidade de nossa parte, da parte de nossas
            afiliadas e de quaisquer terceiros;
          </li>
          <li>Possa ser fraudulento.;</li>
        </ul>
        <div className="terms-and-conditions-title">9 Vigência </div>
        O presente Contrato entrará em vigor na Data de Vigência e permanecerá
        em vigor até ser rescindido. Qualquer notificação de rescisão deste
        Contrato enviada por qualquer uma das partes para a outra parte deve
        incluir uma Data de Rescisão que esteja de acordo com os períodos de
        notificação estabelecidos nas Seções 10 e 11.
        <br />
        <br />
        <div className="terms-and-conditions-title">10 Rescisão </div>
        Este contrato poderá ser rescindido das seguintes formas:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Rescisão por Conveniência. Você poderá rescindir este Contrato por
            qualquer motivo através do envio de notificação para nós, e do
            encerramento da sua conta para todos os serviços para os quais
            fornecemos um mecanismo de encerramento de conta. Nós poderemos
            rescindir este Contrato por qualquer motivo, fornecendo-lhe aviso
            com, no mínimo, 30 dias de antecedência.
          </li>
          <li>
            Rescisão por justa causa por qualquer das Partes. Qualquer parte
            poderá encerrar este Contrato por justa causa se a outra parte
            estiver em descumprimento material deste Contrato, e o
            descumprimento material permaneça não solucionado por um período de
            30 dias contados do recebimento de notificação enviada pela outra
            parte. Você encerrará a sua conta até, no máximo, a Data de
            Rescisão.
          </li>
          <li>
            Rescisão por justa causa pela RNP. A RNP também pode rescindir este
            Contrato imediatamente, mediante envio de notificação a você por
            justa causa se:
          </li>
          <ul className="terms-and-conditions-bullets">
            <li>Se nós tivermos o direito de suspensão;</li>
            <li>
              Se nosso relacionamento com um parceiro, que fornece o software ou
              outra tecnologia que utilizamos para fornecer as Ofertas do
              Serviço expirar, terminar ou exigir que alteremos a forma que
              fornecemos o software ou outra tecnologia como parte dos Serviços;
            </li>
            <li>
              Para cumprir com a lei ou exigências de entidades governamentais.
            </li>
          </ul>
        </ul>
        <div className="terms-and-conditions-title">
          11 Condições gerais da Rescisão{" "}
        </div>
        Em geral, na Data de Rescisão:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Você devolverá imediatamente ou, se por nós instruído, destruirá
            todo o Conteúdo do Serviço LiteCampus em sua posse;
          </li>
          <li>Pós-Rescisão. Seus dados serão removidos após 30 dias.</li>
        </ul>
        <div className="terms-and-conditions-title">
          12 Licença de Ofertas de Serviço{" "}
        </div>
        Nós ou nossos Parceiros detemos e nos reservamos a todos os direitos, a
        propriedade e a participação sobre as Ofertas de Serviço e toda
        tecnologia e direitos de propriedade intelectual relacionados. Sujeito
        aos termos deste Contrato, nós concedemos a você uma licença limitada,
        revogável, não exclusiva, intransferível e não passível de
        sublicenciamento para efetuar o quanto segue:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Acessar e utilizar os Serviços somente de acordo com este Contrato;
          </li>
          <li>
            Copiar e utilizar o Conteúdo do Serviço LiteCampus somente com
            relação ao seu uso permitido dos Serviços.{" "}
          </li>
        </ul>
        <div className="terms-and-conditions-title">
          13 Restrições da Licença{" "}
        </div>
        Nem você nem qualquer Usuário Final utilizarão as Ofertas de Serviço de
        qualquer modo ou para qualquer fim, senão da forma expressamente
        permitida por este Contrato.
        <br />
        <br />
        <div className="terms-and-conditions-title">
          14 Isenção de Responsabilidade{" "}
        </div>
        As ofertas de serviço são fornecidas “no estado em que se encontram”,
        exceto na medida proibida por lei, ou na medida em que direitos
        estatutários sejam aplicáveis e não possam ser afastados, limitados ou
        renunciados, a RNP e seus parceiros:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Não prestamos qualquer declaração ou garantia de qualquer natureza,
            expressa, implícita ou legal ou de outro modo relativamente às
            ofertas de serviço ou ao conteúdo de terceiros;
          </li>
          <li>
            Nos eximimos de todas as responsabilidades com relação a garantias,
            inclusive a garantias implícitas ou explícitas:
          </li>
          <ul className="terms-and-conditions-bullets">
            <li>
              De comerciabilidade, qualidade satisfatória, adequação a um fim
              específico, não violação ou uso manso e pacífico;
            </li>
            <li>
              Advindas de qualquer prática comercial ou uso previamente
              estabelecido;
            </li>
            <li>
              De que as ofertas de serviço ou conteúdo de terceiros serão
              ininterruptos, livres de erros ou de componentes maliciosos;
            </li>
            <li>
              Que quaisquer conteúdos estarão seguros e não serão perdidos ou
              danificados;
            </li>
            <li>
              De precisão das medições coletadas nas instalações do Usuário
              Final;
            </li>
            <li>
              Do reparo e suporte a equipamentos de medição e atuação que não
              sejam fornecidos através deste Contrato;
            </li>
            <li>
              Do curso de instalação de sensores ou atuadores, sejam estes
              fornecidos através deste Contrato ou adquiridos pelo Cliente;
            </li>
            <li>
              De danos materiais ou à saúde de usuários ou instaladores
              decorrentes da instalação de equipamentos de medição e atuação;
            </li>
            <li>
              De justificativas ou explicações à terceiros sobre dados exibidos
              no Serviço LiteCampus e compartilhados.
            </li>
          </ul>
        </ul>
        <div className="terms-and-conditions-title">
          15 Limitação de Responsabilidade{" "}
        </div>
        A RNP e seus parceiros não serão responsáveis perante você por qualquer
        dano direto, indireto, emergente, especial, imprevisto ou multa exemplar
        (incluindo danos por lucros cessantes, perda de receitas, clientes,
        oportunidades, fundo de comércio, uso ou dados, ainda que uma parte
        tenha sido advertida sobre a possibilidade de tais danos. Ainda, nem a
        RNP, nem qualquer parceiro, serão responsáveis por qualquer compensação,
        reembolso, ou danos decorrentes de:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Sua incapacidade de utilizar os serviços, inclusive como resultado
            de qualquer:
          </li>
          <ul className="terms-and-conditions-bullets">
            <li>
              Rescisão ou suspensão do presente contrato ou do seu uso ou acesso
              às ofertas de serviço;
            </li>
            <li>
              Nosso encerramento de quaisquer ou de todas as ofertas de serviço;
            </li>
            <li>
              Sem limitar qualquer obrigação nos termos dos acordos de níveis
              mínimos de serviços, qualquer tempo inoperante não previsto ou
              programado de todos ou de uma parcela dos serviços por qualquer
              motivo.{" "}
            </li>
          </ul>
          <li>Custos da contratação de bens ou serviços substitutos;</li>
          <li>
            Aos investimentos, gastos ou compromissos de sua parte no tocante ao
            presente contrato ou a seu uso ou acesso às ofertas de serviço;{" "}
          </li>
          <li>
            A qualquer acesso desautorizado, alteração ou eliminação,
            destruição, dano, perda ou falha no armazenamento de quaisquer dos
            seus conteúdos ou outros dados.{" "}
          </li>
        </ul>
        <div className="terms-and-conditions-title">
          16 Aditamentos do Contrato{" "}
        </div>
        Nós poderemos alterar este Contrato (inclusive quaisquer Termos e ou
        políticas) a qualquer tempo, colocando uma versão revisada no Site do
        Serviço LiteCampus ou de outro modo notificando-o. Ao continuar
        utilizando as Ofertas de Serviço após a data de vigência de qualquer
        alteração do presente Contrato, você concorda em estar vinculado aos
        termos alterados. É sua responsabilidade verificar o Site do Serviço
        LiteCampus regularmente para obter alterações deste Contrato. Este
        Contrato foi modificado pela última vez na data listada no final dele.
        <br />
        <br />
        <div className="terms-and-conditions-title">17 Disposições Gerais </div>
        Você não irá ceder, nem de qualquer outra forma irá transferir, este
        Contrato ou quaisquer dos seus direitos e obrigações sob este Contrato,
        sem nosso consentimento prévio e por escrito.
        <br />
        <br />
        Este Contrato incorpora, por referência, todas as Políticas e constitui
        o acordo integral entre você e a RNP referente ao objeto deste Contrato.
        Se os termos deste Contrato não forem condizentes com os termos contidos
        em qualquer Política, os termos contidos neste documento prevalecerão,
        exceto pelos Termos de Serviço, que deverão prevalecer sobre este
        documento. Nós não estaremos vinculados por, e especificamente sujeitos
        a qualquer termo, condição ou outra previsão que seja diferente das, ou
        em adição às cláusulas deste Contrato (não importando se tais previsões
        alterariam materialmente os termos deste Contrato ou não), incluindo,
        por exemplo, qualquer termo, condição ou outra previsão:
        <br />
        <br />
        <ul className="terms-and-conditions-bullets">
          <li>
            Submetida por você por meio de ordem, recibo, aceitação,
            confirmação, correspondência ou qualquer outro documento;
          </li>
          <li>
            Relacionado a qualquer registro online, Solicitações de Propostas,
            Solicitação de Informações ou qualquer outro questionário;{" "}
          </li>
          <li>
            Relacionado a qualquer procedimento de faturamento que você submeter
            para nós ou solicitar que nós preenchamos.{" "}
          </li>
        </ul>
        <div className="terms-and-conditions-title">18 Força Maior </div>A RNP e
        seus Parceiros não se responsabilizarão por qualquer atraso ou
        descumprimento de qualquer obrigação aqui pactuada, quando o atraso ou
        descumprimento advir de qualquer causa além do nosso controle razoável,
        inclusive casos fortuitos, ações trabalhistas ou outras perturbações
        industriais, em sistemas elétricos ou outras falhas em serviços de
        telecomunicações, terremotos, tempestades ou outros elementos da
        natureza, bloqueios, embargos, pandemia, motins, atos ou ordens
        governamentais, atos de terrorismos ou guerra.
      </div>
      <Footer />
    </div>
  );
};

export default GeneralUsage;
