import React from "react";
import "./FAQList.scss";
import FAQItemList from "./FAQItemList/FAQItemList";
import InnerFooter from "../../InnerFooter/InnerFooter";

const FAQList = ({ data, faqListStyle }) => {

  return (
    <div>
      <div className='faq-list-header'>
        FAQ - {data.title}
      </div>
      <div className='faq-list-wrapper'>
        <ul className='faq-list'>
          {data.faqs.map((item, key) => {
            return (
              <li className='faq-list-item-wrapper' key={faqListStyle + "-" + key}>
                <FAQItemList item={item} />
              </li>
            )
          })}
        </ul>
      </div>
      <InnerFooter secondLine={"JUNTE-SE A NÓS"} innerFooterStyle="faq"/>
    </div>
  );
};

export default (FAQList);
