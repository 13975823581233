import React from "react";
import { Row } from "react-bootstrap";
import "./DashboardsHeader.scss";

const DashboardsHeader = ({ dashboardStyle }) => {
  return (
    <div className={"dashboard-header-bg " + dashboardStyle}>
      <Row
        className={
          "remove-left-right-margin dashboard-header " + dashboardStyle
        }
      ></Row>
      <Row
        className={
          "remove-left-right-margin dashboard-header-label " + dashboardStyle
        }
      >
        {dashboardStyle === "residence" && "RESIDÊNCIA"}
        {dashboardStyle === "commerce" && "EMPRESA"}
        {dashboardStyle === "industry" && "INDÚSTRIA"}
        {dashboardStyle === "concessionary" && "CONCESSIONÁRIA"}
        {dashboardStyle === "campus" && "CAMPUS"}
      </Row>
    </div>
  );
};

export default DashboardsHeader;
