import React from "react";
import { Row, Col } from "react-bootstrap";
import "./CampusDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import InnerFooter from "../../InnerFooter/InnerFooter";

const CampusDashboard = () => {
  return (
    <div>
      <div id="campus-header-element-div">
        <DashboardsHeader dashboardStyle="campus" />

        <Row className="campus-section video">
          <Col xs={12}>
            <iframe
              title="SmartCampus Video"
              src="https://www.youtube.com/embed/BiX_zLF9VCc"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="video-iframe"
            ></iframe>
          </Col>
        </Row>
      </div>

      <Row
        className="campus-section figure-section"
        id="campus-figure-element-div"
      >
        <Col xs={12} className="campus-two-rows-section-text">
          <div className="campus-two-rows-section-text-wrapper">
            <div className="campus-two-rows-section-header">
              Dashboard para grandes áreas corporativas
            </div>
            Visualização em tempo real do consumo de energia de várias áreas.
            Ideal para clientes que precisam monitorar o consumo de vários
            prédios ou blocos em uma única tela.
          </div>
        </Col>

        <Col
          xs={12}
          className="campus-two-rows-section-img figure-section"
        ></Col>
      </Row>

      <Row className="campus-section text-section" id="campus-text-element-div">
        <Col xs={12} sm={6} className="campus-text-section first">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">Energia e Água</div>
            Dashboards podem ser utilizados para consumo de energia e água de
            grandes áreas. Identificar onde está o maior consumo e visualizar o
            histórico detalhado de consumo estão entre as principais
            funcionalidades.
          </div>
        </Col>
        <Col xs={12} sm={6} className="campus-text-section second">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">Alertas</div>
            Através do dashboard, alertas podem indicar potenciais consumos
            atípicos. Esses alertas podem ser customizados de acordo com
            necessidade do cliente.
          </div>
        </Col>
        <Col xs={12} sm={6} className="campus-text-section third">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">Atuadores</div>
            Atuadores podem ser customizados para garantir que o consumo não
            exista em determinados horários. Desta forma é possível escolher
            horários em que ar condicionados e outros aparelhos não podem ser
            ligados, por exemplo.
          </div>
        </Col>
        <Col xs={12} sm={6} className="campus-text-section fourth">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">
              Integração com outros Dashboards
            </div>
            A partir de uma visão geral no Dashboard é possível investigar
            pontos de interesse a fundo através da integração com o sistema
            LiteMe. Por exemplo, o LiteMe permite analisar históricos em
            diferentes escalas e em diferentes períodos para várias grandezas
            elétricas.
          </div>
        </Col>
        <Col xs={12} sm={6} className="campus-text-section fifth">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">
              Adoção simplificada para membros do sistema RNP
            </div>
            Como consequência da parceria com a Rede Nacional de Pesquisa,
            membros do sistema RNP podem contratar o serviço LiteCampus através
            do portal NasNuvens.
          </div>
        </Col>
        <Col xs={12} sm={6} className="campus-text-section sixth">
          <div className="campus-text-wrapper">
            <div className="campus-text-header">
              Controle de acesso hierárquico
            </div>
            Defina quem pode controlar atuadores e criar alarmes, além de
            visualizar informações detalhadas por bloco.
          </div>
        </Col>
      </Row>

      <InnerFooter secondLine={"JUNTE-SE A NÓS"} innerFooterStyle="campus" />
    </div>
  );
};

export default CampusDashboard;
