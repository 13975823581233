import React from "react";
import "./AreasDashboardSection.scss";
import { Row, Col } from "react-bootstrap";

const AreasDashboardSection = () => {
  return (
    <Row
      className="home-dashboards-section"
      id="home-areas-dashboard-element-div"
    >
      <Col md={12} className="home-section-header">
        VÁRIOS DASHBOARDS
      </Col>

      <Col>
        <Row className="home-dashboards-logos">
          <Col xs={4} className="home-dashboards-logo residence">
            {" "}
            RESIDÊNCIA{" "}
          </Col>
          <Col xs={4} className="home-dashboards-logo commerce">
            {" "}
            EMPRESA{" "}
          </Col>
          <Col xs={4} className="home-dashboards-logo industry">
            {" "}
            INDÚSTRIA{" "}
          </Col>
        </Row>
        <div className="home-dashboards-txt">
          Dashboards que podem ser aplicados para consumidores residenciais,
          comerciais e industriais.
        </div>
        <Row className="home-dashboards-logos">
          <Col xs={12} sm={6}>
            <Row className="home-dashboards-logo concessionary">
              {" "}
              CONCESSIONÁRIA{" "}
            </Row>
            <Row className="home-dashboards-txt-second-row">
              Dashboards para concessionárias, utilizado para gerenciar energia
              importada, exportada, consumo e microgeração de uma grande
              quantidade de unidades consumidoras.
            </Row>
          </Col>
          <Col xs={12} sm={6}>
            <Row className="home-dashboards-logo campus">
              {" "}
              CIDADES E CAMPUS{" "}
            </Row>
            <Row className="home-dashboards-txt-second-row">
              Dashboard para grandes áreas corporativas, ideal para clientes que
              precisam monitorar o consumo de vários prédios ou blocos em uma
              única tela.
            </Row>
          </Col>
        </Row>
      </Col>

      <Col md={12}>
        <div className="home-dashboards-img"></div>
      </Col>

      <Col md={12}>
        <div className="home-app-txt">
          <b>LiteMe</b> disponível para Web e Mobile
        </div>
        <Row className="home-app-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=com.smartiks.litemeapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="app-button google-play"></div>
          </a>
          <a
            href="https://apps.apple.com/br/app/liteme/id1525624210"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="app-button app-store"></div>
          </a>
        </Row>
      </Col>

      <Col md={12}>
        <a href="/dashboards" className="plain-link">
          <div className="meet-dashboards-txt">CONHEÇA OS DASHBOARDS</div>
          <div className="right-arrow-dashboards"></div>
        </a>
      </Col>
    </Row>
  );
};

export default AreasDashboardSection;
