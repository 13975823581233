const measurementsData = [
  {
    question: `Quais sensores são suportados pelo LiteMe?`,
    answer: `A plataforma LiteMe é compatível com diversas modalidades de sensores, sendo estes relacionados a diversas grandezas como energia elétrica, fluxo de água, temperatura, umidade e até contagem de pessoas. Além do LiteMeter, medidor de energia produzido pelo LiteMe, temos suporte a: medidor Yokogawa, PowerTag Schneider, Medidores com interface RS232 que seguem a norma NBR1522 (Ares 7023/8023 da Eletra, por exemplo), inversores Sungrow, entre outros. O suporte a sensores/medidores cresce a cada dia.`
  },
  {
    question: `Como é a calibração do LiteMeter?`,
    answer: `O LiteMeter, nosso Smart Meter responsável por medir grandezas elétricas, foi calibrado em laboratório de Metrologia utilizando o calibrador de energia elétrica Fluke 6100A, capaz de gerar sinais de tensão e corrente (senoides com ou sem distorção) para validar medidores de até 0.1% de precisão. Em adição a esse processo, as medições do LiteMeter também foram comparadas as de outros medidores mais difundidos no mercado, apresentando-se iguais ou até melhores.`
  },
  {
    question: `Qual a precisão do LiteMe?`,
    answer: `O LiteMeter é classificado na Classe A (2,0%).`
  },
  {
    question: `Testei o LiteMe com um outro medidor e observei uma diferença de X%, qual dos dois está errado?`,
    answer: `Existem vários fatores que podem levar a diferenças de medição, os mais básicos são a faixa de medição e diferentes momentos de medição. Em relação à faixa de medição, diferentes equipamentos terão diferentes faixas de operação e assim, como existe um erro que tipicamente é relacionado com a faixa, os diferentes equipamentos terão diferentes erros de medição. Por exemplo, considere dois equipamentos medindo uma corrente de 18 A, um equipamento com operação mais ampla (0-400A) erra em 2A (0,5% em relação à sua escala), medindo 20 A. Outro, com escala mais restrita (0-20 A) erra em 0,5 A (0,5% em relação à sua escala), porém, para menos, observando um valor de 17,5A. No final, a diferença entre os dois seria de 3,5 A, valor aparentemente alto.
    
    Em relação ao medição em diferentes momentos, é preciso observar que se um medidor é local (ex., multímetro) e o outro é baseado na nuvem (LiteMe). O LiteMeter mostra as medições a partir da nuvem, dessa forma, existe uma atraso entre o envio dessas medições e a exibição na interface do usuário, que pode chegar a vários segundos, dependendo da conexão com a Internet.`
  }
];

export default measurementsData;
