import React, { useState, useEffect } from "react";
import "./Dashboards.scss";
import { DashboardsNavConfig } from "../../data/NavBarConfig";
import InnerNavbar from "../InnerNavbar/InnerNavbar";
import ResidenceDashboard from "./ResidenceDashboard/ResidenceDashboard";
import CommerceDashboard from "./CommerceDashboard/CommerceDashboard";
import IndustryDashboard from "./IndustryDashboard/IndustryDashboard";
import ConcessionaryDashboard from "./ConcessionaryDashboard/ConcessionaryDashboard";
import CampusDashboard from "./CampusDashboard/CampusDashboard";
import CitiesDashboard from "./CitiesDashboard/CitiesDashboard";
import Footer from "./../Footer/Footer";

const Dashboards = ({ section, analytics }) => {
  const [currentSection, setCurrentSection] = useState(
    section || DashboardsNavConfig[0].value
  );

  useEffect(() => {
    analytics.pageview(window.location.pathname + "/" + currentSection);

    const pageContainerDiv = document.getElementById(
      "dashboard-page-container"
    );
    pageContainerDiv.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentSection, analytics]);

  const renderSection = {
    residence: <ResidenceDashboard />,
    commerce: <CommerceDashboard />,
    industry: <IndustryDashboard />,
    concessionary: <ConcessionaryDashboard />,
    campus: <CampusDashboard />,
    cities: <CitiesDashboard />
  }[currentSection];

  return (
    <div>
      <InnerNavbar
        activeSection={currentSection}
        changeSection={setCurrentSection}
        sections={DashboardsNavConfig}
      ></InnerNavbar>
      <div className="page-container dashboards" id="dashboard-page-container">
        {renderSection || <CampusDashboard />}
        <Footer />
      </div>
    </div>
  );
};

export default Dashboards;
