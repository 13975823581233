import { FAQNavConfig } from "./FAQData";

const AppNavConfig = [
  {
    value: "dashboards",
    label: "Dashboards"
  },
  {
    value: "devices",
    label: "Dispositivos"
  },
  {
    value: "join",
    label: "Seja LiteMe"
  },
  {
    value: "about",
    label: "Sobre"
  },
  {
    value: "faq",
    label: "FAQ"
  }
];

const DashboardsNavConfig = [
  {
    value: "cities",
    label: "Cidades"
  },
  {
    value: "campus",
    label: "Campus"
  },
  {
    value: "concessionary",
    label: "Concessionária"
  },
  {
    value: "industry",
    label: "Indústria"
  },
  {
    value: "commerce",
    label: "Empresa"
  },
  {
    value: "residence",
    label: "Residência"
  }
];

const NavBarConfig = {
  app: AppNavConfig,
  dashboards: DashboardsNavConfig,
  faq: FAQNavConfig
};

export { NavBarConfig, AppNavConfig, DashboardsNavConfig, FAQNavConfig };
