import React from "react";
import { Row, Col } from "react-bootstrap";
import "./ConcessionaryDashboard.scss";
import DashboardsHeader from "../DashboardsHeader/DashboardsHeader";
import InnerFooter from "../../InnerFooter/InnerFooter";

const ConcessionaryDashboard = () => {
  return (
    <div>
      <div id="concessionary-header-element-div">
        <DashboardsHeader dashboardStyle="concessionary" />

        <Row className="concessionary-initial-text">
          <Col xs={10} sm={7} className="concessionary-initial-text-col">
            O dashboard de concessionária foi criado para permitir que
            concessionárias possam ter uma nova forma de visualizar o consumo e
            microgeração de sua base de clientes. O projeto Neolite é resultado
            de uma parceria com o grupo Neoenergia.
          </Col>
        </Row>

        <Row className="concessionary-section">
          <Col xs={12} className="concessionary-main-img header"></Col>
        </Row>
      </div>

      <Row
        className="concessionary-section text-section"
        id="concessionary-text-element-div"
      >
        <Col xs={12} sm={6} className="concessionary-text-section first">
          <div className="concessionary-text-wrapper">
            <div className="concessionary-text-header">
              Dashboard da Concessionária
            </div>
            Permite visualizar o consumo, geração, energia importada e exportada
            de uma grande área geográﬁca.
          </div>
        </Col>
        <Col xs={12} sm={6} className="concessionary-text-section second">
          <div className="concessionary-text-wrapper">
            <div className="concessionary-text-header">
              Dashboard do consumidor
            </div>
            Consumidores residenciais, comerciais e industriais visualizam dados
            de consumo através do dashboard do LiteMe.
          </div>
        </Col>
        <Col xs={12} sm={6} className="concessionary-text-section third">
          <div className="concessionary-text-wrapper">
            <div className="concessionary-text-header third">Integrações</div>A
            integração com painéis solares e com medidores da concessionária
            permite o consumidor acompanhar seu consumo real, visualizar em uma
            mesma tela quanto foi o consumo e a geração de energia.
          </div>
        </Col>
        <Col xs={12} sm={6} className="concessionary-text-section fourth">
          <div className="concessionary-text-wrapper">
            <div className="concessionary-text-header fourth">
              Perfil de consumo da base de clientes
            </div>
            O dashboard permite visualizar o perfil de consumo dos clientes de
            uma área específica, e com isso saber o consumo por tipo de
            equipamento, por carga, por faixa de horário ou por energia
            importada/exportada, por exemplo.
          </div>
        </Col>
      </Row>

      <InnerFooter
        secondLine={"CONCESSIONÁRIA"}
        innerFooterStyle="concessionary"
      />
    </div>
  );
};

export default ConcessionaryDashboard;
