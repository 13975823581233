import React from "react";
import "../../TermsAndConditions.scss";
import Footer from "../../../Footer/Footer";

const GeneralSafety = () => {
  return (
    <div className="terms-and-conditions-wrapper">
      <div className="terms-and-conditions-header2">
        Versão 1.0 (04 de dezembro de 2020)
      </div>
      <div className="terms-and-conditions-header">
        LiteCampus - Política de Segurança
      </div>
      <div className="terms-and-conditions-text">
        A segurança é a nossa maior prioridade. Como cliente do Serviço do
        LiteCampus, você se beneficiará de um datacenter e de uma arquitetura de
        rede criados para atender aos requisitos das empresas com as maiores
        exigências de segurança. O Serviço do LITECAMPUS não armazena
        informações pessoais nem credenciais de acesso. Todas as informações
        relativas aos dados de cobrança e credenciais são geridas pela equipe
        especializada do portal NasNuvens.
        <br />
        <br />
        Uma vantagem do Serviço do LiteCampus é que ele permite que os clientes
        escalem e inovem, sem deixar de manter um ambiente seguro. Os clientes
        pagam apenas pelos serviços que utilizarem, o que significa que você
        pode ter a segurança de que precisa, mas sem as despesas iniciais, e com
        um custo menor do que o de um ambiente local.
        <br />
        <br />
        Atenção: o Serviço do LiteCampus contém informações importantes sobre os
        hábitos de funcionáriose usuários de suas instalaçõe. Por isso, não
        recomendamos que você compartilhe a sua senha com outras pessoas, já que
        isso pode colocar a segurança dos dados das pessoas de sua comunidade em
        risco.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Confidencialidade </div>
        Permitimos o acesso aos dados e aos nossos sistemas somente a pessoas
        autorizadas, conforme o princípio do privilégio mínimo. Quando incluído
        no plano contratado, os dados mais sensíveis são protegidos em enclaves,
        usando tecnologias de computação confidencial.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Integridade </div>
        Preservamos a confiabilidade dos dados e das informações contra qualquer
        tipo de alteração, seja de forma acidental ou fraudulenta.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Disponibilidade </div>
        Estabelecemos os controles necessários para que as informações estejam
        disponíveis para serem acessadas quando necessário. Quando incluído no
        plano contratado, os dados mais sensíveis são protegidos em enclaves,
        usando tecnologias de computação confidencial.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Auditabilidade </div>
        Propiciamos que qualquer ação ou transação possa ser relacionada
        univocamente, garantindo o cumprimento dos controles fundamentais
        estabelecidos nas respectivas normas.
        <br />
        <br />
        <div className="terms-and-conditions-title"> Considerações gerais </div>
        Se acontecer algum incidente de segurança, nos comprometemos a agir com
        rapidez e responsabilidade para minimizar os impactos e possíveis danos.
        <br />
        <br />
        Também mantemos um plano de continuidade de negócios para diminuir
        possíveis impactos a você que possam afetar a prestação dos serviços.
        <br />
        <br />O Serviço do LiteCampus utiliza recursos para investigar
        vulnerabilidades de segurança que coloquem em risco a sua privacidade,
        garantindo que sejam implantadas as medidas de correção certas.
        <br />
        <br />
        Você será informado apenas dos casos relevantes em que a perda, o uso
        indevido ou a revelação das informações tenham acontecido por uma
        violação da segurança dos sistemas e das redes da companhia ou que
        estejam relacionados a uma decisão ou ação técnica interna. Nesses
        casos, você vai saber quais ações corretivas vão ser realizadas e as
        recomendações para proteger os seus interesses.
        <br />
        <br />
        No nosso relacionamento com as autoridades legais, respeitamos a
        legislação local e a regulamentação.
        <br />
        <br />
        Se você está ciente de qualquer vulnerabilidade ou ameaça, que possa
        afetar a infraestrutura do Serviço do LiteCampus, entre em contato com
        nossa equipe de Resposta a Incidentes de Segurança (CSIRT RNP) através
        do e-mail: cais@cais.rnp.br
      </div>
      <Footer />
    </div>
  );
};

export default GeneralSafety;
