import React from "react";
import LITEMETER_ARTWORK from "../assets/img/devices/litemeter.svg";
import LITETAG_ARTWORK from "../assets/img/devices/litetag.svg";
import YAGUA_ARTWORK from "../assets/img/devices/yagua.svg";
import SONOFF_POW_ARTWORK from "../assets/img/devices/sonoff_pow.svg";
import SONOFF_MINI_ARTWORK from "../assets/img/devices/sonoff_mini.svg";

const LITEMETER_TEXT = (
  <>
    Totalmente desenvolvido pela nossa equipe, o LiteMeter, agora na sexta
    geração, é um multimedidor de energia trifásico bidirecional. O melhor
    custo-benefício do mercado entre riqueza de informações, acurácia e custo.
    Entre os dados medidos, temos: potência ativa, reativa, RMS de tensão e
    corrente, fator de potência e até distorção harmônica. Todos eles enviados
    para nossa nuvem via WiFi ou 3G.
  </>
);

const LITETAG_TEXT = (
  <>
    Também desenvolvido pela nossa equipe, a LiteTag, agora na segunda geração,
    é um dispositivo de excelente custo-benefício que se acopla a medidores de
    energia de concessionárias ou multimedidores populares do mercado, coleta
    todos os dados disponíveis e os envia para nossa nuvem. Uma ótima forma de
    começar a integração com sistemas de monitoramento inteligente, seja via
    WiFi ou 3G.
  </>
);

const YAGUA_TEXT = (
  <>
    <b>Medição de Vazão de Água:</b>
    <br />
    Em desenvolvimento pela Yágua, parceira da Smartiks/LiteMe, o coletor de
    dados de vazão de água é responsável por fazer a leitura dos dados de
    hidrômetros homologados e enviar para nossa nuvem. O dispositivo é
    alimentado por bateria com autonomia de vários anos. Os dados são enviados
    via WiFi, 2G/3G/4G ou SigFox.
  </>
);

const SONOFF_POW_TEXT = (
  <>
    <b>Sonoff Pow:</b> Atuador e medidor de energia monofásico. Além de atuar,
    ele é capaz de medir o consumo de cargas impactantes na conta de energia,
    como aparelhos condicionadores de ar.
  </>
);

const SONOFF_MINI_TEXT = (
  <>
    <b>Sonoff Mini:</b> Atuador simples que pode ser facilmente embutido em
    qualquer tomada convencional.
    <br />
    <br />
    <b>Sonoff TH:</b> Atuador e medidor de temperatura/umidade.
  </>
);

const INTEGRATIONS_TEXT = (
  <>
    O LiteMe oferece suporte a diversos multimedidores populares do mercado de
    empresas como Schneider, Phoenix Contact, Siemens, etc. Os protocolos de
    comunicação suportados são: RS232, RS485, Modbus, REST API, entre outros.
  </>
);

const INTEGRATIONS_ARTWORK_TITLE = (
  <>
    {" "}
    DIVERSAS <br /> INTEGRAÇÕES{" "}
  </>
);

const DevicesData = {
  LITEMETER: {
    id: "litemeter",
    title: "LiteMeter",
    text: LITEMETER_TEXT,
    artwork: LITEMETER_ARTWORK
  },
  LITETAG: {
    id: "litetag",
    title: "LiteTag",
    text: LITETAG_TEXT,
    artwork: LITETAG_ARTWORK,
    inverted: true
  },
  YAGUA: {
    id: "yagua",
    text: YAGUA_TEXT,
    artwork: YAGUA_ARTWORK
  },
  SONOFF_POW: {
    id: "sonoff_pow",
    text: SONOFF_POW_TEXT,
    artwork: SONOFF_POW_ARTWORK,
    inverted: true
  },
  SONOFF_MINI: {
    id: "sonoff_mini",
    text: SONOFF_MINI_TEXT,
    artwork: SONOFF_MINI_ARTWORK
  },
  INTEGRATIONS: {
    id: "integrations",
    text: INTEGRATIONS_TEXT,
    artwork_title: INTEGRATIONS_ARTWORK_TITLE,
    inverted: true
  }
};

export default DevicesData;
