import React from "react";
import "./HowItWorksSection.scss";
import { Row, Col } from "react-bootstrap";

const HowItWorksSection = () => {
  return (
    <div
      className="home-how-it-works-section"
      id="home-how-it-works-element-div"
    >
      <Row className="how-it-works-section-header">COMO FUNCIONA</Row>
      <Row className="how-it-works-figure-section">
        <Col xs={12} sm={6} md={4} className="how-it-works-col">
          <Row className="how-it-works-img sensor"></Row>
          <Row className="how-it-works-text-wrapper">
            <div className="how-it-works-header">Medidor</div>
            Um medidor é instalado, de forma simples e não-invasiva, em sua
            residência, empresa ou indústria.
          </Row>
        </Col>
        <Col xs={12} sm={6} md={4} className="how-it-works-col">
          <Row className="how-it-works-img cloud"></Row>
          <Row className="how-it-works-text-wrapper">
            <div className="how-it-works-header">Dados na Nuvem</div>
            Esse medidor coleta informações sobre o consumo e envia para a
            nuvem, onde os dados são processados.
          </Row>
        </Col>
        <Col xs={12} sm={12} md={4} className="how-it-works-col">
          <Row className="how-it-works-img web"></Row>
          <Row className="how-it-works-text-wrapper">
            <div className="how-it-works-header">LiteMe</div>
            Através do seu computador ou utilizando seu celular, acesse um
            dashboard e descubra onde e quando está consumindo mais e entenda
            como economizar.
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default HowItWorksSection;
