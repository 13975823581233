import React from "react";
import classNames from "classnames";

import "./Bars.scss";

const HexBar = ({ title, value, type }) => {
  return (
    <div className={classNames(["label-container"])}>
      <span className={classNames(["label"])}>{title}</span>
      <span className={classNames(["value", type])}>{value}</span>
    </div>
  );
};

export default HexBar;
