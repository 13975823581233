import React from "react";
import "./Devices.scss";
import DevicesSection from "./DevicesSection/DevicesSection";
import InnerFooter from "../InnerFooter/InnerFooter";
import Footer from "./../Footer/Footer";
import DevicesData from "../../data/DevicesData";

const Devices = () => {
  return (
    <div className="page-container">
      <div className="devices-title-wrapper">
        <div className="devices-title">
          NOSSOS <br /> DISPOSITIVOS
        </div>
      </div>
      <div className="devices-container">
        <DevicesSection config={DevicesData.LITEMETER} />
        <DevicesSection config={DevicesData.LITETAG} />
        <DevicesSection config={DevicesData.YAGUA} />
      </div>
      <div className="devices-title-wrapper">
        <div className="devices-title">ATUAÇÃO</div>
        <div className="devices-subtitle">
          Automatizar os ambientes tem se tornado um desejo comum <br />
          entre diversos tipos de usuários. O LiteMe trabalha com <br />
          os atuadores mais famosos e acessíveis do mercado atual, <br />a
          família Sonoff, desenvolvido pela ITEAD.
        </div>
      </div>
      <div className="devices-container integrations">
        <DevicesSection config={DevicesData.SONOFF_POW} />
        <DevicesSection config={DevicesData.SONOFF_MINI} />
        <DevicesSection config={DevicesData.INTEGRATIONS} />
      </div>
      <InnerFooter innerFooterStyle="devices" />
      <Footer />
    </div>
  );
};

export default Devices;
