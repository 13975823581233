import React from "react";
import "./DevicesSection.scss";
import { Row, Col } from "react-bootstrap";

const DevicesSection = ({ config }) => {
  return (
    <Row
      className={`devices-section ${config.inverted ? "inverted" : ""}`}
      id="devices-section-element-div"
    >
      <Col
        xs={12}
        md={6}
        className={`devices-section-text-wrapper ${config.id}`}
      >
        <div className="devices-section-text">
          {config.title && (
            <div className="devices-section-header">{config.title}</div>
          )}
          {config.text}
        </div>
      </Col>

      <Col xs={12} md={6} className="devices-section-artwork">
        {config.artwork && (
          <img
            className={`devices-artwork ${config.id}`}
            src={config.artwork}
            alt={`${config.id}-artwork`}
          />
        )}
        {config.artwork_title && (
          <div className="devices-artwork-title"> {config.artwork_title} </div>
        )}
      </Col>
    </Row>
  );
};

export default DevicesSection;
