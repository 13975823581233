import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./InnerNavbar.scss";

const InnerNavbar = ({ activeSection, changeSection, sections}) => {

  return (
    <Navbar className="inner-nav">
      <Nav className="nav-links-wrapper">
        {
          sections.map((section, i) => (
            <Nav.Link 
              key={i}
              href="#" 
              className={ activeSection === section.value ? 'selected-section' : '' } 
              onClick={() => changeSection(section.value)}>
                {section.label}
            </Nav.Link>
          ))
        }
      </Nav>
    </Navbar>
  );
};

export default InnerNavbar;
